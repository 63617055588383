var app = angular.module('TCAPP')

app.directive("file", [function () {

    return {
        scope: {
            file: "="
        },
         link: function(scope, el, attrs){
            el.bind('change', function(event){
                var files = event.target.files;
                var file = files[0];
                scope.file = file ? file.name : undefined;
                scope.$apply();
            });
        }
    }
}]);


app.directive('timePicker', ['$window', function($window) {
    return {
      scope: {
        onChange: '&',
        time: '='
      },
      link: function($scope, $element, $attrs) {

        var format  = 'HH:mm:ss';
        var options = {
            date:false,
            format: format,
            time: true,
        };

        $($element).bootstrapMaterialDatePicker(options);
        $element.on('change', function(event, time) {
            
          $scope.$apply(function() {
            $scope.time = time.format(format);
            $scope.onChange({
              time: time
            });
          });
        });

        $scope.$watch('time', function(newValue, oldValue) {
          if (newValue !== oldValue) {
            console.log();
            $($element).bootstrapMaterialDatePicker('setDate', newValue);
          }
        });
      }
    };

}]);

app.directive('starRating', function () {
    return {
        restrict: 'A',
        template: '<ul class="rating">' +
        '<li ng-repeat="star in stars" ng-class="star" ng-click="toggle($index)">' +
        '\u2605' +
        '</li>' +
        '</ul>',
        scope: {
            ratingValue: '=',
            max: '=',
            onRatingSelected: '&'
        },
        link: function (scope, elem, attrs) {

            var updateStars = function () {
                scope.stars = [];
                for (var i = 0; i < scope.max; i++) {
                    scope.stars.push({
                        filled: i < scope.ratingValue
                    });
                }
            };

            scope.toggle = function (index) {
                scope.ratingValue = index + 1;
                scope.onRatingSelected({
                    rating: index + 1
                });
            };

            scope.$watch('ratingValue', function (oldVal, newVal) {
                if (newVal) {
                    updateStars();
                }
            });
        }
    }
});


app.directive('datePicker', ['$window', function($window) {
    return {
      scope: {
        onChange: '&',
        date: '='
      },
      link: function($scope, $element, $attrs) {

        var format  = 'YYYY-MM-DD';
        var options = {
          format: format,
          time: false,
        };

        if ($attrs.minDate) {
          if ($attrs.minDate === 'today') {
            options.minDate = $window.moment();
          } 
          else {
            options.minDate = new Date($attrs.minDate);
          }
        }

        if ($scope.date) {
          options.currentDate = $scope.date;
        }

        $($element).bootstrapMaterialDatePicker(options);

        $element.on('change', function(event, date) {
          $scope.$apply(function() {
            $scope.date = date.format(format);
            $scope.onChange({
              date: date
            });
          });
        });

        $scope.$watch('date', function(newValue, oldValue) {
          if (newValue !== oldValue) {
            $($element).bootstrapMaterialDatePicker('setDate', newValue);
          }
        });
      }
    };
}]);

app.directive('datePickerMultiple', ['$window', function($window) {
    return {
      scope: {
        onChange: '&',
        date: '='
      },
      link: function($scope, $element, $attrs) {

        var format  = 'YYYY-MM-DD';
        var options = {
          format: format,
          time: false,
        };

        if ($attrs.minDate) {
          if ($attrs.minDate === 'today') {
            options.minDate = $window.moment();
          } 
          else {
            options.minDate = new Date($attrs.minDate);
          }
        }

        if ($scope.date) {
          options.currentDate = $scope.date;
        }

        $($element).bootstrapMaterialDatePicker(options);
        
        $element.on('change', function(event, date) {
          $scope.$apply(function() {
            $scope.date = date.format(format);
            $scope.onChange({
              date: date
            });
          });
        });

        $scope.$watch('date', function(newValue, oldValue) {
          if (newValue !== oldValue) {
            $($element.id).bootstrapMaterialDatePicker('setDate', newValue);
          }
        });
      }
    };
}]);

app.directive('dateTimePicker', ['$window', function($window) {
    return {
      scope: {
        onChange: '&',
        date: '='
      },
      link: function($scope, $element, $attrs) {

        var format  = 'YYYY-MM-DD HH:mm';
        var options = {
          format: format,
          time: true,
        };

        if ($attrs.minDate) {
          if ($attrs.minDate === 'today') {
            options.minDate = $window.moment();
          } else {
            options.minDate = $attrs.minDate;
          }
        }

        if ($scope.date) {
          options.currentDate = $scope.date;
        }

        $($element).bootstrapMaterialDatePicker(options);
        $element.on('change', function(event, date) {
          $scope.$apply(function() {
            $scope.date = date.format(format);
            $scope.onChange({
              date: date
            });
          });
        });

        $scope.$watch('date', function(newValue, oldValue) {
          if (newValue !== oldValue) {
            $($element).bootstrapMaterialDatePicker('setDate', newValue);
          }
        });
      }
    };

}]);

app.directive('selectPicker', function($timeout) {
    return {
        link: function(scope, element, attrs) {
            $timeout(function() {
                $(element).selectpicker('refresh');
            }, 800);
        }
    };
});    

app.directive('ckEditor', function () {
    return {
        require: '?ngModel',
        link: function (scope, elm, attr, ngModel) {
            var ck = CKEDITOR.replace(elm[0]);
            if (!ngModel) return;
            ck.on('instanceReady', function () {
                ck.setData(ngModel.$viewValue);
            });
            function updateModel() {
                scope.$apply(function () {
                    ngModel.$setViewValue(ck.getData());
                });
            }
            ck.on('change', updateModel);
            ck.on('key', updateModel);
            ck.on('dataReady', updateModel);

            ngModel.$render = function (value) {
                ck.setData(ngModel.$viewValue);
            };
        }
    };
});

app.directive('uiRequired', function() {
    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
            ctrl.$validators.required = function(modelValue, viewValue) {
                return !((viewValue && viewValue.length === 0 || false) && attrs.uiRequired === 'true');
            };

            attrs.$observe('uiRequired', function() {
                ctrl.$setValidity('required', !(attrs.uiRequired === 'true' && ctrl.$viewValue && ctrl.$viewValue.length === 0));
            });
        }
    };
});

app.directive('accessibleForm', function () {
    return {
        restrict: 'A',
        link: function (scope, elem) {

            // set up event handler on the form element
            elem.on('submit', function () {

                // find the first invalid element
                var firstInvalid = elem[0].querySelector('.ng-invalid');

                // if we find one, set focus
                if (firstInvalid) {
                    firstInvalid.focus();
                }
            });
        }
    };
});

app.directive('validatePdfDoc', function validFile() {

    var validFormats = ['pdf', 'doc','docx'];
    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ctrl) {
            ctrl.$validators.validFile = function() {
                elem.on('change', function () {
                    var value = elem.val(),
                        ext = value.substring(value.lastIndexOf('.') + 1).toLowerCase();   

                    return validFormats.indexOf(ext) !== -1;
                });
            };
        }
    };
});

app.directive('validateDoc', function validFile() {

    var validFormats = ['doc','docx'];
    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ctrl) {
            ctrl.$validators.validFile = function() {
                elem.on('change', function () {
                    var value = elem.val(),
                        ext = value.substring(value.lastIndexOf('.') + 1).toLowerCase();   

                    return validFormats.indexOf(ext) !== -1;
                });
            };
        }
    };
});

app.directive('validatePdf', function validFile() {

    var validFormats = ['doc','docx'];
    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ctrl) {
            ctrl.$validators.validFile = function() {
                elem.on('change', function () {
                    var value = elem.val(),
                        ext = value.substring(value.lastIndexOf('.') + 1).toLowerCase();   
                    return validFormats.indexOf(ext) !== -1;
                });
            };
        }
    };
});


app.directive('validatePdfDoc', function validFile() {

    var validFormats = ['pdf', 'doc','docx'];
    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ctrl) {
            ctrl.$validators.validFile = function() {
                elem.on('change', function () {
                    var value = elem.val(),
                        ext = value.substring(value.lastIndexOf('.') + 1).toLowerCase();   

                    return validFormats.indexOf(ext) !== -1;
                });
            };
        }
    };
});

app.directive('validateVideo', function validFile() {
    var validFormats = ['avi','flv','wmv','mp4','mov'];
    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ctrl) {
            ctrl.$validators.validFile = function() {
                elem.on('change', function () {
                    var value = elem.val(),
                        ext = value.substring(value.lastIndexOf('.') + 1).toLowerCase();
                    return validFormats.indexOf(ext) !== -1;
                });
            };
        }
    };
});

app.directive('validateImg', function validFile() {
    var validFormats = ['jpg','jpeg','png'];
    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ctrl) {
            ctrl.$validators.validFile = function() {
                elem.on('change', function () {
                    var value = elem.val(),
                        ext = value.substring(value.lastIndexOf('.') + 1).toLowerCase();   

                    return validFormats.indexOf(ext) !== -1;
                });
            };
        }
    };
});

app.directive('historyBackward', ['$window', function($window) {
    return {
        restrict: 'A',
        link: function(scope, elem, attrs) {
            elem.bind('click', function() {
                $window.history.back();
            });
        }
    };
}]);

app.directive('errSrc', function() {
    return {
        link: function(scope, element, attrs) {
            element.bind('error', function() {
                if (attrs.src != attrs.errSrc) {
                    attrs.$set('src', attrs.errSrc);
                }
            });

            attrs.$observe('ngSrc', function(value) {
                if (!value && attrs.errSrc) {
                    attrs.$set('src', attrs.errSrc);
                }
            });
        }
    }
});

app.directive('loadingPane', function ($timeout, $window) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            var directiveId = 'loadingPane';

            var targetElement;
            var paneElement;
            var throttledPosition;

            function init(element) {
                targetElement = element;

                paneElement = angular.element('<div>');
                paneElement.addClass('loading-pane');

                if (attr['id']) {
                    paneElement.attr('data-target-id', attr['id']);
                }

                var spinnerImage = angular.element('<div>');
                spinnerImage.addClass('spinner-image');
                spinnerImage.appendTo(paneElement);

                angular.element('body').append(paneElement);

                setZIndex();

                //reposition window after a while, just in case if:
                // - watched scope property will be set to true from the beginning
                // - and initial position of the target element will be shifted during page rendering
                $timeout(position, 100);
                $timeout(position, 200);
                $timeout(position, 300);

                throttledPosition = _.throttle(position, 50);
                angular.element($window).scroll(throttledPosition);
                angular.element($window).resize(throttledPosition);
            }

            function updateVisibility(isVisible) {
                if (isVisible) {
                    show();
                } else {
                    hide();
                }
            }

            function setZIndex() {                
                var paneZIndex = 500;

                paneElement.css('zIndex', paneZIndex).find('.spinner-image').css('zIndex', paneZIndex + 1);
            }

            function position() {
                paneElement.css({
                    'left': targetElement.offset().left,
                    'top': targetElement.offset().top - $(window).scrollTop(),
                    'width': targetElement.outerWidth(),
                    'height': targetElement.outerHeight()
                });
            }

            function show() {
                paneElement.show();
                position();
            }

            function hide() {
                paneElement.hide();
            }

            init(element);

            scope.$watch(attr[directiveId], function (newVal) {
                updateVisibility(newVal);
            });

            scope.$on('$destroy', function cleanup() {
                paneElement.remove();
                $(window).off('scroll', throttledPosition);
                $(window).off('resize', throttledPosition);
            });
        }
    };
});

app.directive('ngFiles', ['$parse', function ($parse) {

    function fn_link(scope, element, attrs) {
        var onChange = $parse(attrs.ngFiles);
        element.on('change', function (event) {
            onChange(scope, { $files: event.target.files });
        });
    };

    return {
        link: fn_link
    }
} ]);


app.directive('onlyDigits', function () {

    return {
        restrict: 'A',
        require: '?ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            modelCtrl.$parsers.push(function (inputValue) {
                if (inputValue == undefined) return '';
                var transformedInput = inputValue.replace(/[^0-9]/g, '');
                if (transformedInput !== inputValue) {
                    modelCtrl.$setViewValue(transformedInput);
                    modelCtrl.$render();
                }
                return transformedInput;
            });
        }
    };
});

app.directive('onlyAlpha', function () {
    return {
        restrict: 'A',
        require: '?ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            modelCtrl.$parsers.push(function (inputValue) {
                if (inputValue == undefined) return '';
                var transformedInput = inputValue.replace(/[^a-z]/g, '');
                if (transformedInput !== inputValue) {
                    modelCtrl.$setViewValue(transformedInput);
                    modelCtrl.$render();
                }
                return transformedInput;
            });
        }
    };
});

app.directive('validateEmail', function() {
    var EMAIL_REGEXP = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
    return {
        link: function(scope, elm) {
            elm.on("keyup",function(){
                var isMatchRegex = EMAIL_REGEXP.test(elm.val());
                if( isMatchRegex&& elm.hasClass('warning') || elm.val() == ''){
                    elm.removeClass('redborder');
                }else if(isMatchRegex == false && !elm.hasClass('warning')){
                    elm.addClass('redborder');
                }
            });
        }
    }
});


app.directive('countrySelect', function(){
    function link(scope, elem, attrs){
        //You can do fun stuff here if you want 
        // console.log(countries);       
    }
    return {
        scope: {
            countries: '=',
            monthChoice: '='
        },
        link: link,
        template: '<select ng-model="monthChoice" ng-options="country.country_name for country in countries" class="monthsClass"></select>'
    }
});


app.directive('validFile',function(){
    return {
        require:'ngModel',
        link:function(scope,el,attrs,ngModel){
            //change event is fired when file is selected
            el.bind('change',function(){
                scope.$apply(function(){
                    ngModel.$setViewValue(el.val());
                    ngModel.$render();
                })
            })
        }
    }
});

app.directive('valid_File', function () {
    return {
        require: 'ngModel',
        link: function (scope, el, attrs, ngModel) {
            ngModel.$render = function () {
                ngModel.$setViewValue(el.val());
            };

            el.bind('change', function () {
                scope.$apply(function () {
                    ngModel.$render();
                });
            });
        }
    };
});


app.directive('spinner', function () {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            name: '@?',
            group: '@?',
            show: '=?',
            imgSrc: '@?',
            register: '@?',
            onLoaded: '&?',
            onShow: '&?',
            onHide: '&?'
        },
        template: [
            '<span ng-show="show">',
            '  <img ng-show="imgSrc" ng-src="{{imgSrc}}" />',
            '  <span ng-transclude></span>',
            '</span>'
        ].join(''),
        controller: function ($scope, spinnerService) {

            // register should be true by default if not specified.
            if (!$scope.hasOwnProperty('register')) {
                $scope.register = true;
            } else {
                $scope.register = !!$scope.register;
            }

            // Declare a mini-API to hand off to our service so the 
            // service doesn't have a direct reference to this
            // directive's scope.
            var api = {
                name: $scope.name,
                group: $scope.group,
                show: function () {
                    $scope.show = true;
                },
                hide: function () {
                    $scope.show = false;
                },
                toggle: function () {
                    $scope.show = !$scope.show;
                }
            };

            // Register this spinner with the spinner service.
            if ($scope.register === true) {
                spinnerService._register(api);
            }

            // If an onShow or onHide expression was provided,
            // register a watcher that will fire the relevant
            // expression when show's value changes.
            if ($scope.onShow || $scope.onHide) {
                $scope.$watch('show', function (show) {
                    if (show && $scope.onShow) {
                        $scope.onShow({
                            spinnerService: spinnerService,
                            spinnerApi: api
                        });
                    } else if (!show && $scope.onHide) {
                        $scope.onHide({
                            spinnerService: spinnerService,
                            spinnerApi: api
                        });
                    }
                });
            }

            // This spinner is good to go.
            // Fire the onLoaded expression if provided.
            if ($scope.onLoaded) {
                $scope.onLoaded({
                    spinnerService: spinnerService,
                    spinnerApi: api
                });
            }
        }
    };
});

app.directive('fbLike', [
    '$window', '$rootScope', function ($window, $rootScope) {
        return {
            restrict: 'A',
            scope: {
                fbLike: '=?'
            },
            link: function (scope, element, attrs) {
                if (!$window.FB) {
                    // Load Facebook SDK if not already loaded
                    $.getScript('//connect.facebook.net/en_US/sdk.js', function () {
                        $window.FB.init({
                            appId: $rootScope.facebookAppId,
                            xfbml: true,
                            version: 'v2.0'
                        });
                        renderLikeButton();
                    });
                } else {
                    renderLikeButton();
                }

                var watchAdded = false;
                function renderLikeButton() {
                    if (!!attrs.fbLike && !scope.fbLike && !watchAdded) {
                        // wait for data if it hasn't loaded yet
                        watchAdded = true;
                        var unbindWatch = scope.$watch('fbLike', function (newValue, oldValue) {
                            if (newValue) {
                                renderLikeButton();

                                // only need to run once
                                unbindWatch();
                            }

                        });
                        return;
                    } else {
                        element.html('<div class="fb-like"' + (!!scope.fbLike ? ' data-href="' + scope.fbLike + '"' : '') + ' data-layout="button_count" data-action="like" data-show-faces="true" data-share="true"></div>');
                        $window.FB.XFBML.parse(element.parent()[0]);
                    }
                }
            }
        };
    }
]);

app.directive('googleplace', function() {
    return {
        require: 'ngModel',
        scope: {
            address: "=",
            lat: "=",
            long: "=",
            country: "=",
            state: "=",
            city: "=",
            error:"="
        },
        link: function(scope, element, attrs, model,$scope) {
            var lat = '';
            var long = '';
            var options = {
                types: []
            };
            var place = {
                address:'',
                lat:'',
                long:'',
                country:'',
                state:'',
                city:'',
                error:true
            }
            scope.gPlace = new google.maps.places.Autocomplete(element[0], options);

            google.maps.event.addListener(scope.gPlace, 'place_changed', function() {
                // if(scope.gPlace.gm_accessors_.place.dd.place.geometry){
                //  place.lat = scope.gPlace.gm_accessors_.place.dd.place.geometry.viewport.f.b;
                //      place.long = scope.gPlace.gm_accessors_.place.dd.place.geometry.viewport.b.f;
                //      place.address = element.val();
                // }
                console.log(scope.gPlace.gm_accessors_.place.fd.place.address_components);
                if(scope.gPlace.gm_accessors_.place.fd.place.address_components.length >= 3){
                    place.error = false;
                    var location = scope.gPlace.gm_accessors_.place.fd.place.address_components;
                    //get country state city
                    for (var i in location) {
                        if (location[i].types[0] == 'country') {
                           place.country = location[i].long_name;
                           console.log(place.country);
                        }
                        else if (location[i].types[0] == 'administrative_area_level_1') {
                           place.state = location[i].long_name;
                           console.log(place.state);
                        }
                        else if (location[i].types[0] == 'locality') {
                           place.city = location[i].long_name;
                           console.log(place.city);
                        }
                        else if (location[i].types[0] == 'administrative_area_level_2') {
                           place.city = location[i].long_name;
                           console.log(place.city);
                        }
                        // else if (!location[i].types[0] == 'administrative_area_level_2' && !location[i].types[0] == 'locality') {
                        //    place.city = location[i].long_name;
                        //    console.log(place.city);
                        // }
                    }
                    //get lat  and lang
                    place.lat = scope.gPlace.gm_accessors_.place.fd.place.geometry.viewport.f.b;
                    place.long = scope.gPlace.gm_accessors_.place.fd.place.geometry.viewport.b.f;
                    //set val
                    place.address = element.val();
                    scope.$apply(function() {
                        model.$setViewValue(place);                
                    });
                }
                else{
                    place.country ='';
                    place.state = '';
                    place.city = '';
                    place.lat = '';
                    place.long = '';
                    place.error = true;
                    scope.$apply(function() {
                        model.$setViewValue(place);                
                    });
                }
            });
        }
    };
});

app.directive('addressBasedGoogleMap', function () {
    return {
        restrict: "A",
        template: "<div id='addressMap'></div>",
        scope: {
            address: "=",
            zoom: "=",
            lat: "=",
            long: "="
        },
        controller: function ($scope) {
            var geocoder;
            var latlng;
            var map;
            var marker;
            var initialize = function () {
                geocoder = new google.maps.Geocoder();
                latlng = new google.maps.LatLng(-34.397, 150.644);
                var mapOptions = {
                    zoom: $scope.zoom,
                    center: latlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                };
                map = new google.maps.Map
                (document.getElementById('addressMap'), mapOptions);
            };
            var markAdressToMap = function () {
                geocoder.geocode({ 'address': $scope.address }, 
                function (results, status) 
                {
                    if (status == google.maps.GeocoderStatus.OK) {
                        map.setCenter(results[0].geometry.location);
                        marker = new google.maps.Marker({
                            map: map,
                            position: results[0].geometry.location
                        });
                    }
                });
            };
            var markLatLngToMap = function (lat,lng) {
                position = new google.maps.LatLng(lat, lng);
                map.setCenter(position);
                marker = new google.maps.Marker({
                            map: map,
                            position: position
                        });
         
            };
            $scope.$watch("address", function () {
                if($scope.lat != undefined && $scope.long != undefined){
                    markLatLngToMap($scope.lat,$scope.long);
                }
                else if ($scope.address != undefined) {
                    markAdressToMap();
                }
            });
            initialize();
        },
    };
});

app.directive('googlePlus', [
    '$window', function ($window) {
        return {
            restrict: 'A',
            scope: {
                googlePlus: '=?'
            },
            link: function (scope, element, attrs) {
                if (!$window.gapi) {
                    // Load Google SDK if not already loaded
                    $.getScript('//apis.google.com/js/platform.js', function () {
                        renderPlusButton();
                    });
                } else {
                    renderPlusButton();
                }

                var watchAdded = false;
                function renderPlusButton() {
                    if (!!attrs.googlePlus && !scope.googlePlus && !watchAdded) {
                        // wait for data if it hasn't loaded yet
                        watchAdded = true;
                        var unbindWatch = scope.$watch('googlePlus', function (newValue, oldValue) {
                            if (newValue) {
                                renderPlusButton();

                                // only need to run once
                                unbindWatch();
                            }

                        });
                        return;
                    } else {
                        element.html('<div class="g-plusone"' + (!!scope.googlePlus ? ' data-href="' + scope.googlePlus + '"' : '') + ' data-size="medium"></div>');
                        $window.gapi.plusone.go(element.parent()[0]);
                    }
                }
            }
        };
    }
]);

app.directive('tweet', [
    '$window', '$location',
    function ($window, $location) {
        return {
            restrict: 'A',
            scope: {
                tweet: '=',
                tweetUrl: '='
            },
            link: function (scope, element, attrs) {
                if (!$window.twttr) {
                    // Load Twitter SDK if not already loaded
                    $.getScript('//platform.twitter.com/widgets.js', function () {
                        renderTweetButton();
                    });
                } else {
                    renderTweetButton();
                }

                var watchAdded = false;
                function renderTweetButton() {
                    if (!scope.tweet && !watchAdded) {
                        // wait for data if it hasn't loaded yet
                        watchAdded = true;
                        var unbindWatch = scope.$watch('tweet', function (newValue, oldValue) {
                            if (newValue) {
                                renderTweetButton();

                                // only need to run once
                                unbindWatch();
                            }
                        });
                        return;
                    } else {
                        element.html('<a href="https://twitter.com/share" class="twitter-share-button" data-text="' + scope.tweet + '" data-url="' + (scope.tweetUrl || $location.absUrl()) + '">Tweet</a>');
                        $window.twttr.widgets.load(element.parent()[0]);
                    }
                }
            }
        };
    }
]);

app.directive('pinIt', [
    '$window', '$location',
    function ($window, $location) {
        return {
            restrict: 'A',
            scope: {
                pinIt: '=',
                pinItImage: '=',
                pinItUrl: '='
            },
            link: function (scope, element, attrs) {
                if (!$window.parsePins) {
                    // Load Pinterest SDK if not already loaded
                    (function (d) {
                        var f = d.getElementsByTagName('SCRIPT')[0], p = d.createElement('SCRIPT');
                        p.type = 'text/javascript';
                        p.async = true;
                        p.src = '//assets.pinterest.com/js/pinit.js';
                        p['data-pin-build'] = 'parsePins';
                        p.onload = function () {
                            if (!!$window.parsePins) {
                                renderPinItButton();
                            } else {
                                setTimeout(p.onload, 100);
                            }
                        };
                        f.parentNode.insertBefore(p, f);
                    }($window.document));
                } else {
                    renderPinItButton();
                }

                var watchAdded = false;
                function renderPinItButton() {
                    if (!scope.pinIt && !watchAdded) {
                        // wait for data if it hasn't loaded yet
                        watchAdded = true;
                        var unbindWatch = scope.$watch('pinIt', function (newValue, oldValue) {
                            if (newValue) {
                                renderPinItButton();

                                // only need to run once
                                unbindWatch();
                            }
                        });
                        return;
                    } else {
                        element.html('<a href="//www.pinterest.com/pin/create/button/?url=' + (scope.pinItUrl || $location.absUrl()) + '&media=' + scope.pinItImage + '&description=' + scope.pinIt + '" data-pin-do="buttonPin" data-pin-config="beside"></a>');
                        $window.parsePins(element.parent()[0]);
                    }
                }
            }
        };
    }
]);


app.directive('ngIntlTelInput', ['ngIntlTelInput', '$log', function (ngIntlTelInput, $log) {
     return {
         restrict: 'A',
         require: 'ngModel',
         link: function (scope, elm, attr, ctrl) {
             // Warning for bad directive usage.
             if (attr.type !== 'text' || elm[0].tagName !== 'INPUT') {
                 $log.warn('ng-intl-tel-input can only be applied to a *text* input');
                 return;
             }
             // Override default country.
             if (attr.defaultCountry) {
                 ngIntlTelInput.set({defaultCountry: attr.defaultCountry});
             }
             // Initialize.
             ngIntlTelInput.init(elm);
             // Validation.
             ctrl.$validators.ngIntlTelInput = function (value) {
                 return elm.intlTelInput("isValidNumber");
             };
             // Set model value to valid, formatted version.
             ctrl.$parsers.push(function (value) {
                 return elm.intlTelInput('getNumber').replace(/[^\d]/, '');
             });
             // Set input value to model value and trigger evaluation.
             ctrl.$formatters.push(function (value) {
                 if (value) {
                     value = value.charAt(0) === '+' || '+' + value;
                     elm.intlTelInput('setNumber', value);
                 }
                 return value;
             });
         }
     };
 }]);

app.directive("ngFileSelect",function(){
    return {
        link: function($scope,el){

            el.bind("change", function(e){

                $scope.file = (e.srcElement || e.target).files[0];
                $scope.getFile();
            })
        }
    }
});