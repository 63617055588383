var app = angular.module('TCAPP')
 
app.factory('tcAjax', function($http){
    return {
        http: function(params,method_name,loader,_successCallback,_errorCallback) {
        	
        	if(loader)
        		$('.loading-overlay').show();
        	
            $http({
                method: 'POST', 
                url: method_name,
              	headers: { 'Content-Type': 'application/json' },
                data: params
            }).then(function successCallback(response) {
            	if(loader)
            		$('.loading-overlay').hide();

                _successCallback(response.data);
            }, function errorCallback(response) {
                _errorCallback(response);
            }); 
        },
        httpUpload: function(params,method_name,loader,_successCallback,_errorCallback) {

        	if(loader)
        		$('.loading-overlay').show();

            $http({
                method: 'POST',
                url: method_name,
                headers: {'Content-Type': undefined},
                transformRequest: angular.identity,
                data: params
            }).then(function successCallback(response) {
            	if(loader)
            		$('.loading-overlay').hide();

                _successCallback(response.data);
            }, function errorCallback(response) {
                _errorCallback(response);
            }); 
        },

        httpget: function(method_name,_successCallback,_errorCallback) {
            $http({
                method: 'GET',
                url: method_name,
                headers: {'Content-Type': 'application/json; charset=utf-8'},
            }).then(function successCallback(response) {
                _successCallback(response.data);
            }, function errorCallback(response) {
                _errorCallback(response);
            }); 
        }
    };
});


app.service('tcLocation', function($http) {
 
    this.getStates = function(country_id,callback) {
    	if(country_id){
    		$http({
			method: 'POST',
			url: 'api/locations/get_states',
			headers: {'Content-Type': 'application/json'},
			data: JSON.stringify({country: country_id})
			})
			.then(function (resp, status) { 
				callback(resp.data.states);
			});
    	}
    }
    this.getCities = function(state_id,callback) {
    	if(state_id){
    		$http({
			method: 'POST',
			url: 'api/locations/get_cities',
			headers: {'Content-Type': 'application/json'},
			data: JSON.stringify({state: state_id})
			})
			.then(function (resp, status) { 
				callback( resp.data.cities);
			});
    	}
    }
});

app.service('tcCommon', function($http, $rootScope,tcAjax) {
 
    this.getPositions = function(query,callback) {
    	if(query){
    		$http.get('api/helper/get_matched_positions?keyword=' + query, { cache: true}).then(function(response) {
				callback(response.data.matched_positions);
			})
    	}
    }

    this.verifyEmail = function(email,userdata,callback) {
    	
    	if(email && email.length < 1){
    		$http({
			method: 'post',
			url: 'api/account/verifyEmail',
			headers: {'Content-Type': 'application/json'},
			data: {email: email, userdata: userdata}
			})
			.then(function (resp, status) { 
				if (resp.data.status === 302) {
					callback(true)
				}
				else if (resp.data.status === 200) {
					callback(false);
				}
			});
    	}
    	else{
			callback(false);
    	}

    }


    /*Start Mobile OTP*/

    this.SendOTP = function(dial_code,mobile_number,callback) {
            $http({
                method: 'post',
                url: 'api/account/send_otp',
                headers: {'Content-Type': 'application/json'},
                data: { dial_code: dial_code, mobile_number: mobile_number}
            })
            .then(function (resp, status) { 
                callback(resp)
            });
    }

    this.verifyOTP = function(dial_code,mobile_number,otp,callback) {
            $http({
                method: 'post',
                url: 'api/account/verifyOtp',
                headers: {'Content-Type': 'application/json'},
                data: { dial_code: dial_code, mobile_number: mobile_number, otp: otp}
            })
            .then(function (resp, status) { 
                callback(resp)
            });
    }
    /*End Mobile OTP*/

    this.verifyEmail = function(email,callback) {
        if(email != undefined){
            $http({
                method: 'post',
                url: 'api/account/verifyEmail',
                headers: {'Content-Type': 'application/json'},
                data: {email: email}
            })
            .then(function (resp, status) { 
                callback(resp)
            });
        }
    }

    this.verifyCompanyEmail = function(email,callback) {
        if(email != undefined){
            $http({
                method: 'post',
                url: 'api/account/verifyCompanyEmail',
                headers: {'Content-Type': 'application/json'},
                data: {email: email}
            })
            .then(function (resp, status) { 
                callback(resp)
            });
        }
    }

    this.verifyMobile = function(mobile,callback) {
        if(mobile != undefined){
            $http({
                method: 'post',
                url: 'api/account/verifyMobile',
                headers: {'Content-Type': 'application/json'},
                data: {mobile: mobile}
            })
            .then(function (resp, status) { 
                callback(resp)
            });
        }
    }

    this.verifyLandline = function(landline,callback) {
        if(landline != undefined){
            $http({
                method: 'post',
                url: 'api/account/verifyLandline',
                headers: {'Content-Type': 'application/json'},
                data: {landline: landline}
            })
            .then(function (resp, status) { 
                callback(resp)
            });
        }
    }

    this.drawPieChart = function(container,title,data) {
        if(container != undefined && title != undefined && data != undefined){

            Highcharts.chart({
                chart: {
                    renderTo: container,
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: title
                },
                tooltip: {
                    formatter: function() {
                        return  ''+ this.point.name +': <b>' + this.point.y + '</b> <br/>  Percentage: <b>'+  this.point.percentage.toFixed(2)  +'%</b>';
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    }
                },
                series: [{
                    // name: 'Brands',
                    colorByPoint: true,
                    data: data
                }]
            });
        }
    }

    this.drawBarChart = function(container,title,categories,series) {
        if(container != undefined && title != undefined && categories != undefined&& series != undefined){

           Highcharts.chart({
                chart: {
                    renderTo: container,
                    type: 'column',
                    options3d: {
                        enabled: true,
                        alpha: 15,
                        beta: 0,
                        depth: 50
                    }
                },
                title: {
                    text: title
                },
                xAxis: {
                    categories:categories
                },
                series: series
            });
        }
    }

    this.getWeightageChart = function(user_id, seo_url, title_name) {

        var params = JSON.stringify({userData: $rootScope.userData,user_id: user_id, job_seo_url: seo_url}) //,4,2,1,6;
        var url = 'api/jobs/get_match_for_user_and_job';
        tcAjax.http(params,url,true,function(resp){
            var job = resp;
            job.title_name = title_name;
            generatetWeightageChart(job);
        },function(error) {});
   
        function generatetWeightageChart(job){
            var donut_data = [];
            donut_data.push(job.job_matched_weightage['certification'].value);
            donut_data.push(job.job_matched_weightage['functionality'].value);
            donut_data.push(job.job_matched_weightage['industry'].value);
            donut_data.push(job.job_matched_weightage['position'].value);
            donut_data.push(job.job_matched_weightage['qualification'].value);
            donut_data.push(job.job_matched_weightage['skills'].value);

            var data = {};

            data['labels'] = ["certification", "functionality", "industry", "position", "qualification", "skills"];

            data['backgroundColor'] = [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ];

            data['borderColor'] = [
                'rgba(255,99,132,1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ];
            data['data'] = donut_data;
             initiateWeightageChart(data,job);
            $('#weightahe_chart_modal').modal('toggle');
        } 

        function initiateWeightageChart(data,job){
            var datas = [];

            $.each(data.labels,function(key,val){
                datas.push([val,parseInt(data.data[key])]); //
            });
            console.log(datas);
            Highcharts.chart('myChart', {
                chart: {
                    type: 'pie',
                    options3d: {
                        enabled: true,
                        alpha: 45
                    }
                },
                title: {
                    text: job.title_name +' - <b>'+job.job_matched_weightage.total_match+' %</b>'
                },
                subtitle: {
                    // text: job.job_position +' - <b>'+job.job_match+' %</b>'
                },
                plotOptions: {
                    pie: {
                        innerSize: 100,
                        depth: 45
                    }
                },
                series: [{
                    name: 'Matched Percentage',
                    data: datas,
                    showInLegend: true
                }]
            });
        }

    }

    this.AddToWishlist = function(id, type, callback){
        if(!$rootScope.userData.authenticated)
        {
            $rootScope.ShowSignIn();
        }
        else{
            if($rootScope.userData.user_type_id == 2 || $rootScope.userData.user_type_id == 4 || $rootScope.userData.user_type_id == 8 ){
                if(type== 'C'){
                    var statusText = 'course';
                }
                else if(type== 'CON'){
                    statusText = 'conference';       
                }
                swal({
                    title: "Are you sure want to add this "+statusText+" in wishlist?",
                    text: "",
                    type: 'info',
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    closeOnConfirm: false
                }, function () {
                    addwishlist(id,type);
                }); 
            }
            else{
                swal('Failed','Student / Professional / Corporate only can wishlist','info');
            }
        }

        function addwishlist(id,type){
            var url = 'api/course/addwishlist';
            var params = JSON.stringify({userData: $rootScope.userData,id:id,type:type});

            tcAjax.http(params,url,false,
                function(resp){             
                    if(resp.status == 200){
                        swal('Success',resp.message,'success');
                        // $scope.course.wishlist = true;
                        callback(resp);
                    }
                    else if(resp.status == 302){
                        swal('Already added',resp.message,'info');
                    }
                    else if(resp.status == 303){
                        swal('Failed',resp.message,'info');
                    }
                    else{
                        // swal('Failed',resp.message,'error');
                        $rootScope.ShowSignIn();
                    }
                },
                function(error) { }
            );
        }

    }
});

app.service('tcService', function() {
    this.getObjectVal = function (arrayval,para,val) {
       return $.grep(arrayval, function(e){ return  e[para] == val })[0]
    }
});

app.factory('AuthenticationService',
    ['$http', '$cookieStore', '$rootScope', '$timeout','$location', '$window', '$localStorage',
     function ($http, $cookieStore, $rootScope, $timeout, $location, $window, $localStorage) {
         var service = {};

         service.Login = function (signin, callback) {
             $http.post('api/account/signinPost', {'signin':signin })
                 .then(function (response) {
                 callback(response);
             });

         };

         service.SetUserData = function (scope) {

             $rootScope.userData = scope;

             var now = new Date(),
                 exp = new Date(now.getFullYear()+1, now.getMonth(), now.getDate());
             $http.defaults.headers.common['Authorization'] = 'Basic ' + $rootScope.userData; // jshint ignore:line
             // $cookieStore.put('userData', $rootScope.userData, {expires: exp});
             $localStorage.userData = $rootScope.userData;
             return $rootScope.userData;
         };

         service.ClearCredentials = function () {
             $rootScope.userData = {};
             $localStorage.userData = {};
             // $cookieStore.remove('userData');
             $http.defaults.headers.common.Authorization = 'Basic ';
             return $rootScope.userData;
             // $location.path('/home');
         };

         return service;
}]);

app.factory('alert', function($uibModal) {
    function show(action, event) {
      return $uibModal.open({
        templateUrl: 'ngviews/uiCalendar/modalContent.html',
        controller: function() {
          var vm = this;
          vm.action = action;
          vm.event = event;
        },
        controllerAs: 'vm'
      });
    }
    return {
      show: show
    };
});