var app = angular.module('TCAPP', [
    'ngRoute',
    'angularFileUpload',
    'ngCookies',
    'oc.lazyLoad',
    'angularUtils.directives.dirPagination',
    'ngStorage',
    'slick',
    'ngImageInputWithPreview',
    'ui.bootstrap',
    'ngTagsInput',
    '720kb.socialshare',
    'updateMeta',
    'ngQuill',
    'uiCropper',
    'ngDialog','mwl.calendar', 'ngAnimate', 'colorpicker.module',
    'jkAngularRatingStars',
    'oi.select',
    'ngSanitize',
    'dndLists',
    'pdf',
    'mkkVideo',
    'ion.rangeslider',
    'pascalprecht.translate'
]);

app.config(['$translateProvider', function ($translateProvider, $translatePartialLoaderProvider, $translatePartialLoader) {
  // add translation tables
    $translateProvider.useStaticFilesLoader({
      prefix: 'dist/js/i18n/locale-',
      suffix: '.json'
    });
    $translateProvider.preferredLanguage('en');
    // $translateProvider.forceAsyncReload(true);

}]);


app.config(function($logProvider){
    $logProvider.debugEnabled(true);
});

app.config(['$httpProvider', function($httpProvider) {
    $httpProvider.defaults.useXDomain = true;
    delete $httpProvider.defaults.headers.common['X-Requested-With'];
}]);
 
app.config(['ngQuillConfigProvider', function (ngQuillConfigProvider) {
    ngQuillConfigProvider.set();
}]);

app.config(['$routeProvider','$locationProvider', function ($routeProvider,$locationProvider, $http,$templateCache) {

    $routeProvider

    /*Start New HOme*/
        .when('/', {
            templateUrl: 'ngviews/home_ui.html',
            controller: 'HomeCtrl',
            requireLogin: false,
            data: {
                'meta': {
                    'title': 'Training Calendar | Global Skill Development Platform powered by AI',
                    'og:title': 'Training Calendar | Global Skill Development Platform powered by AI',
                    'og:description': 'One Stop Destination to access a global range of training and executive education providers that can fulfill a professional, student or individual skill gaps. Online Courses, Corporate Training, Management Training in Dubai,Employee Training Programs,Training in Dubai',
                }
            },
            
        })
        .when('/home/', {
            templateUrl: 'ngviews/home_ui.html',
            controller: 'HomeCtrl',
            requireLogin: false,
            data: {
                'meta': {
                    'title': 'Training Calendar | Global Skill Development Platform powered by AI',
                    'og:title': 'Training Calendar | Global Skill Development Platform powered by AI',
                    'og:description': 'One Stop Destination to access a global range of training and executive education providers that can fulfill a professional, student or individual skill gaps. Online Courses, Corporate Training, Management Training in Dubai,Employee Training Programs,Training in Dubai',
                }
            },
            
        })
        /*End New HOme*/


        // 	.when('/signin', {
        // 	templateUrl: 'view/account/signin',
        // 	controller: 'SigninCtrl',
        // 	requireLogin: false,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js',
        // 			])
        // 		}]
        // 	}
        // })
        // .when('/signout', {
        //     template: '',
        //     requireLogin: false,
        //     controller: function($rootScope, $localStorage, $location, $http){
        //         $('.loading-overlay').show();
        //         $http({
        //             method: 'post',
        //             url: 'api/account/logout',
        //             headers: {'Content-Type': 'application/json'},
        //             data: {userData :$rootScope.userData}
        //         }).then(function(resp,status) {
        //             $('.loading-overlay').hide();
        //             $location.path('/');
        //             if (resp.data.status === 200) {
        //                 // swal('Logged out Successfully!',resp.data.message,'success');
        //                 $rootScope.userData = {};
        //                 $localStorage.userData = {};
        //             }
        //             else{
        //                 swal('',resp.data.message,'info');
        //             }
        //         });
        //     }
        // })
        // 	.when('/forgotpass', {
        // 	templateUrl: 'view/account/forgotpass',
        // 	controller: 'ForgotCtrl',
        // 	requireLogin: false,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js',
        // 			])
        // 		}]
        // 	}
        // })
        .when('/forgotpass/reset/:email/:token', {
            templateUrl: 'ngviews/forgotpass_resetform.html',
            controller: 'ForgotResetFormCtrl',
            requireLogin: false,
            
        })
        // 	.when('/signup', {
        // 	templateUrl: 'view/account/signup',
        // 	controller: 'SignupCtrl',
        // 	requireLogin: false,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        // 	.when('/signup/success', {
        // 	templateUrl: 'view/account/signup_success',
        // 	controller: 'SignupCtrl',
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })


        .when('/listing-filter', {
            templateUrl: 'ngviews/listing-filter.html',
            controller: 'ListingCtrl',
            requireLogin: false,
            
        })
        .when('/create-blog', {
            templateUrl: 'ngviews/blog/create_post.html',
            controller: 'CreatePostCtrl',
            requireLogin: false,
            
        })
        .when('/edit-blog/:seourl', {
            templateUrl: 'ngviews/blog/edit_post.html',
            controller: 'EditPostCtrl',
            requireLogin: false,
            
        })

        .when('/my-posts', {
            templateUrl: 'ngviews/blog/my-posts.html',
            controller: 'MyPostsCtrl',
            requireLogin: false,
            
        })

        .when('/blogs', {
            templateUrl: 'ngviews/blog/blogs.html',
            controller: 'BlogListCtrl',
            requireLogin: false,
            
        })
        .when('/blog/:seourl', {
            templateUrl: 'ngviews/blog/blog.html',
            controller: 'BlogCtrl',
            requireLogin: false,
            
        })
        .when('/specializations/', {
            templateUrl: 'ngviews/specializations.html',
            controller: 'SpecializationsCtrl',
            requireLogin: false,
            
        })
        .when('/specializations/:seourl', {
            templateUrl: 'ngviews/courses.html',
            controller: 'SpecializationsCoursesCtrl',
            requireLogin: false,
            
        })
        .when('/specializations/subs/:seourl/', {
            templateUrl: 'ngviews/specializations.html',
            controller: 'SubSpecializationsCtrl',
            requireLogin: false,
            
        })
        .when('/subspecializations/:seourl', {
            templateUrl: 'ngviews/courses.html',
            controller: 'SubSpecializationsCoursesCtrl',
            requireLogin: false,
            
        })
        .when('/courses/', {
            templateUrl: 'ngviews/courses.html',
            controller: 'CoursesCtrl',
            requireLogin: false,
            
        })

        // 	.when('/courses/liked', {
        // 	templateUrl: 'view/courses/index',
        // 	controller: 'CoursesLikedCtrl',
        // 	requireLogin: false,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js',
        // 			])
        // 		}]
        // 	}
        // })
        .when('/courses/nearby', {
            templateUrl: 'ngviews/courses.html',
            controller: 'CoursesLocationCtrl',
            requireLogin: false,
            
        })
        .when('/executive-education/', {
            templateUrl: 'ngviews/executivecourses.html',
            controller: 'ExecutiveCoursesCtrl',
            requireLogin: false,
            
        })

        .when('/signup/', {
            templateUrl: 'ngviews/guide-selection.html',
            controller: 'SignUpCtrl',
            requireLogin: false
            // resolve: {
            // 	loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
            // 		return $ocLazyLoad.load([
            // 			'dist/js/controllers.min.js',
            // 		])
            // 	}]
            // }
        })

        // 	.when('/executive-education/liked', {
        // 	templateUrl: 'view/courses/executivecourses',
        // 	controller: 'LikedExecutiveCoursesCtrl',
        // 	requireLogin: false,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js',
        // 			])
        // 		}]
        // 	}
        // })
        // 	.when('/executive-education/:seourl', {
        // 	templateUrl: 'view/courses/course',
        // 	controller: 'ExecutiveCourseCtrl',
        // 	requireLogin: false,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js',
        // 			])
        // 		}]
        // 	}
        // })
        .when('/executive-education/courses/:seourl', {
            templateUrl: 'ngviews/executivecourses.html',
            controller: 'ExecutiveCourseFilterCtrl',
            requireLogin: false,
            
        })
        .when('/rfps/', {
            templateUrl: 'ngviews/proposals.html',
            controller: 'ProposalsCtrl',
            requireLogin: false,
            
        })
        .when('/rfps/:seourl', {
            templateUrl: 'ngviews/proposal.html',
            controller: 'ProposalCtrl',
            requireLogin: false,
            
        })
        .when('/trainer-vacancies', {
            templateUrl: 'ngviews/vacancies.html',
            controller: 'VacanciesCtrl',
            requireLogin: false,
            type:'list',
            
        })
        .when('/trainer-vacancies/map', {
            templateUrl: 'ngviews/vacancies.html',
            controller: 'VacanciesCtrl',
            requireLogin: false,
            type:'map',
            
        })
        .when('/trainer-vacancies/:seourl', {
            templateUrl: 'ngviews/vacancy.html',
            controller: 'VacancyCtrl',
            requireLogin: false,
            
        })
        .when('/courses/:seourl', {
            templateUrl: 'ngviews/course.html',
            controller: 'CourseCtrl',
            requireLogin: false,
            
        })
        .when('/course/register/success/:seourl', {
            templateUrl: 'ngviews/course_register_success.html',
            controller: 'CourseRegCtrl',
            requireLogin: false,
            
        })
        .when('/conferences/', {
            templateUrl: 'ngviews/conferences.html',
            controller: 'ConferencesCtrl',
            requireLogin: false,
            
        })
        // 	.when('/conferences/liked', {
        // 	templateUrl: 'view/conferences/index',
        // 	controller: 'LikedConferencesCtrl',
        // 	requireLogin: false,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js',
        // 			])
        // 		}]
        // 	}
        // })
        .when('/conferences/:seourl', {
            templateUrl: 'ngviews/conference.html',
            controller: 'ConferenceCtrl',
            requireLogin: false,
            
        })
        .when('/conference/register/success/:seourl', {
            templateUrl: 'ngviews/conference_register_success.html',
            controller: 'ConferenceRegCtrl',
            requireLogin: false,
            
        })
        .when('/trainers', {
            templateUrl: 'ngviews/trainers.html',
            controller: 'TrainersCtrl',
            requireLogin: false,
            
        })
        // 	.when('/profile/:seourl', {
        // 	templateUrl: 'view/users/user',
        // 	controller: 'UserCtrl',
        // 	requireLogin: false,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js',
        // 			])
        // 		}]
        // 	}
        // })
        .when('/trainers/:seourl', {
            templateUrl: 'ngviews/trainer.html',
            controller: 'TrainerCtrl',
            requireLogin: false,
            
        })
        .when('/training-providers', {
            templateUrl: 'ngviews/training_providers.html',
            controller: 'TrainingProvidersCtrl',
            requireLogin: false,
            
        })
        .when('/training-providers/:seourl', {
            templateUrl: 'ngviews/training_provider.html',
            controller: 'TrainingProviderCtrl',
            requireLogin: false,
            
        })
        .when('/recruiter/:seourl', {
            templateUrl: 'ngviews/recruiter.html',
            controller: 'RecruiterCtrl',
            requireLogin: false,
            
        })
        .when('/executive-education-provider/:seourl', {
            templateUrl: 'ngviews/executive_provider.html',
            controller: 'ExecutiveProviderCtrl',
            requireLogin: false,
            
        })


        .when('/view-profile/:seourl', {
            templateUrl: 'ngviews/profile_resume.html',
            controller: 'ProfileInfoCtrl',
            requireLogin: false,
            
        })

        .when('/universities/:seourl', {
            templateUrl: 'ngviews/university.html',
            controller: 'UniversityCtrl',
            requireLogin: false,
            
        })
        .when('/free-courses/', {
            templateUrl: 'ngviews/freecourses.html',
            controller: 'FreeCoursesCtrl',
            requireLogin: false,
            
        })
        // 	.when('/free-courses/:seourl', {
        // 	templateUrl: 'view/courses/course',
        // 	controller: 'FreeCourseCtrl',
        // 	requireLogin: false,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js',
        // 			])
        // 		}]
        // 	}
        // })
        .when('/free-courses/courses/:seourl', {
            templateUrl: 'ngviews/freecourses.html',
            controller: 'FreeCourseProviderCtrl',
            requireLogin: false,
            
        })
        .when('/search', {
            templateUrl: 'ngviews/search.html',
            controller: 'SearchCtrl',
            requireLogin: false,
            
        })
        .when('/not-found', {
            templateUrl: 'ngviews/error404.html',
            controller: 'ErrorCtrl',
            requireLogin: false,
            
        })

        .when('/user/followers', {
            templateUrl: 'ngviews/followers.html',
            controller: 'FollowersCtrl',
            requireLogin: true,
            
        })


        .when('/user/upskills/skill-gap-tna/skills-matrix/:seourl', {
            templateUrl: 'ngviews/skill-matrix.html',
            controller: 'SkillMatrix',
            requireLogin: true,
            
        })
        // 	.when('/provider/followers', {
        // 	templateUrl: 'view/training_provider/followers',
        // 	controller: 'FollowersCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        .when('/user/following', {
            templateUrl: 'ngviews/user/following.html',
            controller: 'FollowingCtrl',
            requireLogin: true,
            
        })

        // 	.when('/corporate/add-line-manager', {
        // 	templateUrl: 'view/corporate/add_hrmanager_view',
        // 	controller: 'AddHrmanagerCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        // 	.when('/corporate/add-section-manager', {
        // 	templateUrl: 'view/corporate/add_sectionmanager_view',
        // 	controller: 'AddSectionmanagerCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        // 	.when('/corporate/managers-list', {
        // 	templateUrl: 'view/corporate/managers_list',
        // 	controller: 'HrmanagersCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        // 	.when('/corporate/section-managers-list', {
        // 	templateUrl: 'view/corporate/section_managers_list',
        // 	controller: 'SectionManagersCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        .when('/user/employee/add', {
            templateUrl: 'ngviews/add_employee.html',
            controller: 'AddEmployeeCtrl',
            requireLogin: true,
            
        })
        .when('/user/employee/edit/:seourl', {
            templateUrl: 'ngviews/edit_employee.html',
            controller: 'EditEmployeeCtrl',
            requireLogin: true,
            
        })
        .when('/user/employees', {
            templateUrl: 'ngviews/employees.html',
            controller: 'EmployeesList',
            requireLogin: true,
            
        })

        .when('/user/students/add', {
            templateUrl: 'ngviews/add_student.html',
            controller: 'AddStudentCtrl',
            requireLogin: true,
            
        })
        .when('/user/students/edit/:seourl', {
            templateUrl: 'ngviews/edit_student.html',
            controller: 'EditStudentCtrl',
            requireLogin: true,
            
        })
        .when('/user/students', {
            templateUrl: 'ngviews/students.html',
            controller: 'StudentsList',
            requireLogin: true,
            
        })
        .when('/user/campus-jobs', {
            templateUrl: 'ngviews/campus_jobs.html',
            controller: 'CampusJobsCtrl',
            requireLogin: true,
            
        })
        .when('/user/campus-jobs/applied-students/:job_id', {
            templateUrl: 'ngviews/campus_jobs_applied_students.html',
            controller: 'CampusJobsAppliedStudentsCtrl',
            requireLogin: true,
            
        })
        .when('/user/upskills/validated-courses', {
            templateUrl: 'ngviews/validated_courses.html',
            controller: 'ValidatedCourses',
            requireLogin: true,
            
        })
        // 	.when('/corporate/employees-courses-for-jd', {
        // 	templateUrl: 'view/corporate/employees_accepted_courses',
        // 	controller: 'EmpCalendarCourses',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        // 	.when('/corporate/jds', {
        // 	templateUrl: 'view/corporate/jds',
        // 	controller: 'UploadJd',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        .when('/user/upskills/jds/upload', {
            templateUrl: 'ngviews/jd_upload.html',
            controller: 'JdSkillsUploadCtrl',
            requireLogin: true,
            
        })
        .when('/user/upskills/jds', {
            templateUrl: 'ngviews/jd_skills.html',
            controller: 'JdSkillsList',
            requireLogin: true,
            
        })
        .when('/user/upskills/skill-gap-tna', {
            templateUrl: 'ngviews/jd_skill_gap.html',
            controller: 'JdSkillsList',
            requireLogin: true,
            
        })
        //CorporateCtrl.js
        .when('/user/upskills/jds/courses/:seourl', {
            templateUrl: 'ngviews/jd_courses.html',
            controller: 'JdCoursesCtrl',
            requireLogin: true,
            
        })
        //CorporateCtrl.js
        .when('/user/upskills/jd/edit', {
            templateUrl: 'ngviews/jdmatrix_edit.html',
            controller: 'JdMatrixEdit',
            requireLogin: true,
            
        })
        .when('/user/upskills/trainings', {
            templateUrl: 'ngviews/upskill_trainings.html',
            controller: 'UpSkillsTrainings',
            requireLogin: true,
            
        })


        .when('/user/rfps/applied', {
            templateUrl: 'ngviews/applied_rfps.html',
            controller: 'AppliedRfpsCtrl',
            requireLogin: true,
            
        })
        .when('/user/trainer-jobs/applied', {
            templateUrl: 'ngviews/trainer_jobs_applied.html',
            controller: 'TrainerJobsAppliedCtrl',
            requireLogin: true,
            
        })
        .when('/user/trainer-jobs/recommended', {
            templateUrl: 'ngviews/jobs_recommended.html',
            controller: 'TrainerRecommendedVacanciesCtrl',
            requireLogin: true,
            
        })
        .when('/user/course/create', {
            templateUrl: 'ngviews/user/create_course.html',
            controller: 'CreateCourseCtrl',
            requireLogin: true,
            
        })
        .when('/user/courses', {
            templateUrl: 'ngviews/user/posted_courses.html',
            controller: 'PostedCoursesCtrl',
            requireLogin: true,
            
        })
        .when('/user/course/edit/:seourl', {
            templateUrl: 'ngviews/user/edit_course.html',
            controller: 'CourseEditCtrl',
            requireLogin: true,
            
        })
        .when('/user/course/duplicate/:seourl', {
            templateUrl: 'ngviews/user/duplicate_course.html',
            controller: 'CourseDuplicateCtrl',
            requireLogin: true,
            
        })
        .when('/user/courses/enrollments', {
            templateUrl: 'ngviews/enrolled_courses.html',
            controller: 'CourseRegistrationsCtrl',
            requireLogin: true,
            
        })
        .when('/user/course/enrollments/:seourl', {
            templateUrl: 'ngviews/enrolled_course.html',
            controller: 'CourseEnrollmentUsersCtrl',
            requireLogin: true,
            
        })


        .when('/user/trends', {
            templateUrl: 'ngviews/user/trends.html',
            controller: 'TrendsCtrl',
            requireLogin: true,
            
        })
        .when('/user/courses/enquiry/leads', {  //not working
            templateUrl: 'ngviews/user/courses_leads.html',
            controller: 'CoursesLeadsCtrl',
            requireLogin: true,
            
        })
        .when('/user/courses/enquiry/chart', {
            templateUrl: 'ngviews/user/leads_chart.html',
            controller: 'LeadsChartCtrl',
            requireLogin: true,
            
        })

        .when('/user/courses/liked/leads', {
            templateUrl: 'ngviews/user/likedcourses_leads.html',
            controller: 'LikedCoursesLeadsCtrl',
            requireLogin: true,
            
        })

        .when('/user/uploadcv', {
            templateUrl: 'ngviews/user/upload_cv.html',
            controller: 'CvUploadCtrl',
            requireLogin: true,
            
        })

        .when('/user/search_candidates', {
            templateUrl: 'ngviews/user/save_jd.html',
            controller: 'SaveJdForCandidateCtrl',
            requireLogin: true,
            
        })
        .when('/user/search_candidates/:jd_id', {
            templateUrl: 'ngviews/user/search_candidates_for_jd.html',
            controller: 'SearchCandidatesCtrl',
            requireLogin: true,
            
        })
        .when('/user/jd_list', {
            templateUrl: 'ngviews/user/jd_list.html',
            controller: 'JdListCtrl',
            requireLogin: true,
            
        })
        .when('/user/candidates_list', {
            templateUrl: 'ngviews/user/candidates_list.html',
            controller: 'CandidatesListCtrl',
            requireLogin: true,
            
        })
        .when('/user/client/add', {
            templateUrl: 'ngviews/user/add_my_client.html',
            controller: 'AddClientCtrl',
            requireLogin: true,
            
        })
        .when('/user/clients', {
            templateUrl: 'ngviews/user/my_clients.html',
            controller: 'ClientsCtrl',
            requireLogin: true,
            
        })

        .when('/user/trainers', {
            templateUrl: 'ngviews/my_trainers.html',
            controller: 'ProviderTrainersCtrl',
            requireLogin: true,
            
        })
        .when('/user/trainer/add', {
            templateUrl: 'ngviews/add_trainer.html',
            controller: 'ProviderAddTrainerCtrl',
            requireLogin: true,
            
        })

        .when('/user/recomended-corporates', {
            templateUrl: 'ngviews/my_recommended_corporates.html',
            controller: 'UniversityCorporateCtrl',
            requireLogin: true,
            
        })
        .when('/user/corporate-recommend/add', {
            templateUrl: 'ngviews/add_corporate_recommend.html',
            controller: 'UniversityAddCorporateCtrl',
            requireLogin: true,
            
        })
        // 	.when('/provider/clients', {
        // 	templateUrl: 'view/training_provider/training_provider_clients',
        // 	controller: 'ProviderClientsCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        // 	.when('/provider/client/add', {
        // 	templateUrl: 'view/training_provider/training_provider_add_client',
        // 	controller: 'AddClientCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })

        // 	.when('/provider/client/add', {
        // 	templateUrl: 'view/executive/add_my_client',
        // 	controller: 'AddClientCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        // 	.when('/provider/clients', {
        // 	templateUrl: 'view/executive/my_clients',
        // 	controller: 'ClientsCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })

        // 	.when('/provider/leads', {
        // 	templateUrl: 'view/training_provider/courses_leads',
        // 	controller: 'CoursesLeadsCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        // 	.when('/provider/courses/liked', {
        // 	templateUrl: 'view/training_provider/liked_courses_leads',
        // 	controller: 'LikedCoursesLeadsCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        // 	.when('/provider/course/edit/:seourl', {
        // 	templateUrl: 'view/training_provider/training_provider_edit_course',
        // 	controller: 'CourseEditCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        // 	.when('/provider/trends', {
        // 	templateUrl: 'view/training_provider/training_provider_trends',
        // 	controller: 'ProviderTrendsCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        .when('/user/brochure', {
            templateUrl: 'ngviews/my_brochure.html',
            controller: 'BrochureCtrl',
            requireLogin: true,
            
        })

        .when('/user/conference/create', {
            templateUrl: 'ngviews/create_conference.html',
            controller: 'ProviderConferenceCtrl',
            requireLogin: true,
            
        })
        .when('/user/conferences',{
            templateUrl: 'ngviews/posted_conferences.html',
            controller: 'AuthorConferencesCtrl',
            requireLogin: true,
            
        })
        .when('/user/conferences/enrollments',{
            templateUrl: 'ngviews/conferences_enrollments.html',
            controller: 'ProviderConferenceCtrl',
            requireLogin: true,
            
        })
        .when('/user/conference/enrollments/:seourl',{
            templateUrl: 'ngviews/conference_enrollment.html',
            controller: 'ProviderConferenceCtrl',
            requireLogin: true,
            
        })
        .when('/user/conference/edit/:seourl',{
            templateUrl: 'ngviews/edit_conference.html',
            controller: 'ProviderConferenceCtrl',
            requireLogin: true,
            
        })
        .when('/user/trainer-job/create',{
            templateUrl: 'ngviews/create_trainer_job.html',
            controller: 'VacancyCreateCtrl',
            requireLogin: true,
            
        })
        .when('/user/trainer-job/edit/:seourl',{
            templateUrl: 'ngviews/edit_trainer_job.html',
            controller: 'VacancyEditCtrl',
            requireLogin: true,
            
        })

        .when('/user/trainer-jobs',{
            templateUrl: 'ngviews/trainer_jobs_posted.html',
            controller: 'VacancyListCtrl',
            requireLogin: true,
            
        })
        // 	.when('/provider/vacancy/corporate',{
        // 	templateUrl: 'view/training_provider/training_provider_vacancies_corporate',
        // 	controller: 'ProviderVacancyFromCorporateCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        .when('/user/trainer-job/compare/:seourl',{
            templateUrl: 'ngviews/trainer_job_compare.html',
            controller: 'VacancyChangeStatusCtrl',
            requireLogin: true,
            
        })
        // Training Providers Ends //

        // Individual Starts //

        .when('/user/profile', {
            templateUrl: 'ngviews/profile.html',//partails + ctrl
            controller: 'ProfileCtrl',
            requireLogin: true,
            
        })

        .when('/user/profile/edit', {
            templateUrl: 'ngviews/update_profile.html',
            controller: 'ProfileUpdateCtrl',
            requireLogin: true,
            
        })
        .when('/user/policies', {
            templateUrl: 'ngviews/user_policies.html',
            controller: 'PolicyCtrl',
            requireLogin: true,
            
        })
        .when('/user/dashboard', {
            templateUrl: 'ngviews/dashboard.html',
            controller: 'DashboardCtrl',
            requireLogin: true,
            
        })
        .when('/user/corporate-dashboard', {
            templateUrl: 'ngviews/user/corporate-dashboard.html',
            controller: 'CorpDashboardCtrl',
            requireLogin: true,
            
        })
        .when('/user/recruitment/dashboard', {
            templateUrl: 'ngviews/user/recruitment-dashboard.html',
            controller: 'CorpDashboardCtrl',
            requireLogin: true,
            
        })

        // 	.when('/user/dashboard', {
        // 	templateUrl: 'view/individual/individual_dashboard',
        // 	controller: 'DashboardCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })
        /*	.when('/user/profile/edit', {
		templateUrl: 'view/individual/individual_edit_profile',
		controller: 'ProfileEditCtrl',
		requireLogin: true,
		resolve: {
			loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
				return $ocLazyLoad.load([
					'dist/js/controllers.min.js'
				]);
			}]
		}
	})*/

        .when('/user/courses/registered', {
            templateUrl: 'ngviews/user/registered_courses.html',
            controller: 'RegisteredCoursesCtrl',
            requireLogin: true,
            
        })

        .when('/user/courses/feedback-questions', {
            templateUrl: 'ngviews/user/feedback_questions.html',
            controller: 'FeedbackQuestionsCtrl',
            requireLogin: true,
            
        })

        .when('/user/executive-courses/registered', {
            templateUrl: 'ngviews/user/registered_courses.html',
            controller: 'RegisteredExecutiveCoursesCtrl',
            requireLogin: true,
            
        })
        .when('/user/conferences/registered', {
            templateUrl: 'ngviews/conferences_registered.html',
            controller: 'RegisteredConferencesCtrl',
            requireLogin: true,
            
        })

        .when('/user/skills/update', {
            templateUrl: 'ngviews/user/skills_update.html',
            controller: 'SkillsCtrl',
            requireLogin: true,
            
        })

        .when('/user/skills/chart', {
            templateUrl: 'ngviews/user/skills_chart.html',
            controller: 'ChartCtrl',
            requireLogin: true,
            
        })
        .when('/user/skills/celebrity', {
            templateUrl: 'ngviews/user/skills_celebrity.html',
            controller: 'CelebrityCtrl',
            requireLogin: true,
            
        })

        .when('/user/geography/training', {
            templateUrl: 'ngviews/user/geography_courses.html',
            controller: 'GeographyCoursesCtrl',
            requireLogin: true,
            
        })

        .when('/user/courses/recommended', {
            templateUrl: 'ngviews/user/recommended_courses.html',
            controller: 'RecomendedCoursesCtrl',
            requireLogin: true,
            
        })
        .when('/user/executive-courses/recommended', {
            templateUrl: 'ngviews/user/recommended_courses.html',
            controller: 'RecomendedExecutiveCoursesCtrl',
            requireLogin: true,
            
        })
        // 	.when('/user/recommended/skills/courses', {
        // 	templateUrl: 'view/individual/individual_recommended_skills_courses',
        // 	controller: 'IndRecomendedSkillCtrl',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })

        .when('/user/courses/liked', {
            templateUrl: 'ngviews/user/liked_courses.html',
            controller: 'LikedCoursesCtrl',
            requireLogin: true,
            
        })
        .when('/user/executive-courses/liked', {
            templateUrl: 'ngviews/user/liked_courses.html',
            controller: 'LikedExecutiveCoursesCtrl',
            requireLogin: true,
            
        })
        .when('/user/conferences/liked', {
            templateUrl: 'ngviews/user/liked_conferences.html',
            controller: 'LikedConferencesCtrl',
            requireLogin: true,
            
        })

        .when('/user/skills/trainers', {
            templateUrl: 'ngviews/user/rolemodel_trainers.html',
            controller: 'IndTrainerSkillCtrl',
            requireLogin: true,
            
        })

        .when('/user/mycalendar', {
            templateUrl: 'ngviews/user/calendar.html',
            controller: 'myCalendarCtrl',
            requireLogin: true,
            
        })

        // Individual Ends //



        .when('/user/upskills/dashboard', {
            templateUrl: 'ngviews/upskill_dahsboard.html',
            controller: 'CorpUpskillDashboardCtrl',
            requireLogin: true,
            
        })

        .when('/user/upskills/hierarchical-chart', {
            templateUrl: 'ngviews/hierarchical_chart.html',
            controller: 'CorpHirchlChartCtrl',
            requireLogin: true,
            
        })

        .when('/user/rfp/create', {
            templateUrl: 'ngviews/create_rfp.html',
            controller: 'CorpCreateRfpCtrl',
            requireLogin: true,
            
        })

        .when('/user/rfp/edit/:seourl', {
            templateUrl: 'ngviews/edit_rfp.html',
            controller: 'CorpEditRfpCtrl',
            requireLogin: true,
            
        })

        .when('/user/rfps', {
            templateUrl: 'ngviews/rfps_posted.html',
            controller: 'CorpRfpsListCtrl',
            requireLogin: true,
            
        })

        .when('/user/rfp/compare/:seourl', {
            templateUrl: 'ngviews/rfp_compare.html',
            controller: 'CorpRFPCompareCtrl',
            requireLogin: true,
            
        })

        .when('/user/rfp/finalize/:seourl', {
            templateUrl: 'ngviews/rfp_finalize.html',
            controller: 'CorpRFPFinalizeCtrl',
            requireLogin: true,
            
        })

        .when('/user/rfps/completed', {
            templateUrl: 'ngviews/rfp_completed',
            controller: 'CorpRfpsListCtrl',
            requireLogin: true,
            
        })

        .when('/user/upskills/employees/skills-validation',{
            templateUrl: 'ngviews/user_skills_validation.html',
            controller: 'CorpEmpSkillsCtrl',
            requireLogin: true,
            
        })

        .when('/jobs/', {
            templateUrl: 'ngviews/jobs.html',
            controller: 'JobsCtrl',
            requireLogin: false,
            
        })

        .when('/campus-jobs/', {
            templateUrl: 'ngviews/jobs.html',
            controller: 'JobsCtrl',
            requireLogin: false,
            jobType:'Campus',
            
        })
        .when('/jobs/filter/:j', {
            templateUrl: 'ngviews/jobs.html',
            controller: 'JobsCtrl',
            requireLogin: false,
            
        })
        .when('/campus-jobs/filter/:j', {
            templateUrl: 'ngviews/jobs.html',
            controller: 'JobsCtrl',
            jobType:'Campus',
            requireLogin: false,
            
        })

        .when('/jobs/:seourl', {
            templateUrl: 'ngviews/job.html',
            controller: 'JobCtrl',
            requireLogin: false,
            
        })

        .when('/jobs-map/', {
            templateUrl: 'ngviews/job_map_view.html',
            controller: 'JobsMapCtrl',
            requireLogin: false,
            
        })
        .when('/jobs-map/:type', {
            templateUrl: 'ngviews/job_map_view.html',
            controller: 'JobsMapCtrl',
            requireLogin: false,
            
        })

        .when('/user/professional-job/create', {
            templateUrl: 'ngviews/create_professional_job.html',
            controller: 'ProfessionalVacancyCtrl',
            requireLogin: true,
            
        })

        .when('/user/professional-job-weightage/:seourl', {
            templateUrl: 'ngviews/update_weightage.html',
            controller: 'ProfessionalWeightageCtrl',
            requireLogin: true,
            
        })

        .when('/user/campus-job/create/:type', {
            templateUrl: 'ngviews/create_professional_job.html',
            controller: 'ProfessionalVacancyCtrl',
            requireLogin: true,
            
        })

        .when('/user/professional-job/edit/:seourl', {
            templateUrl: 'ngviews/edit_professional_job.html',
            controller: 'ProfessionalVacancyEditCtrl',
            requireLogin: true,
            
        })
        .when('/user/campus-job/edit/:seourl/:type', {
            templateUrl: 'ngviews/edit_professional_job.html',
            controller: 'ProfessionalVacancyEditCtrl',
            requireLogin: true,
            
        })

        .when('/user/professional-jobs', {
            templateUrl: 'ngviews/professional_jobs_posted.html',
            controller: 'ProfessionalVacancyListCtrl',
            requireLogin: true,
            
        })

        .when('/user/campus-jobs/:type', {
            templateUrl: 'ngviews/campus_jobs_list.html',
            controller: 'ProfessionalVacancyListCtrl',
            requireLogin: true,
            
        })

        .when('/user/professional-job/candidates/:seourl', {
            templateUrl: 'ngviews/professional_job_candidates.html',
            controller: 'JobsSearchFindCandidatesCtrl',
            requireLogin: true,
            
        })

        .when('/user/campus-job/candidates/:seourl', {
            templateUrl: 'ngviews/professional_job_candidates.html',
            controller: 'JobsSearchFindCandidatesCtrl',
            requireLogin: true,
            jobType:'Campus',
            
        })

        .when('/user/professional-job/rejected/:seourl', {
            templateUrl: 'ngviews/professional_job_rejected.html',
            controller: 'JobsRejectedCandidatesCtrl',
            requireLogin: true,
            
        })

        .when('/user/campus-job/rejected/:seourl', {
            templateUrl: 'ngviews/professional_job_rejected.html',
            controller: 'JobsRejectedCandidatesCtrl',
            requireLogin: true,
            jobType:'Campus',
            
        })
        // 	.when('/user/job/student_recommended_course', {
        // 	templateUrl: 'view/individual/student_recommended_course',
        // 	controller: 'StdRecomCourse',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}

        // })

        .when('/user/select-target-jobs', {
            templateUrl: 'ngviews/user/select_target_job.html',
            controller: 'IndTargetJobCtrl',
            requireLogin: true,
            
        })

        .when('/user/professional-jobs/jobbox', {
            templateUrl: 'ngviews/user/job_box.html',
            controller: 'JobBoxCtrl',
            requireLogin: true,
            

        })

        .when('/user/professional-jobs/applied', {
            templateUrl: 'ngviews/user/applied_jobs.html',
            controller: 'AppliedJobsCtrl',
            requireLogin: true,
            
        })
        .when('/user/campus-jobs/applied/campus', {
            templateUrl: 'ngviews/user/applied_jobs.html',
            controller: 'AppliedJobsCtrl',
            requireLogin: true,
            jobType:'Campus',
            
        })

        .when('/user/upgrade-to-professional', {
            templateUrl: 'ngviews/user/upgrade_to_professional.html',
            controller: 'UpgradeToProfesssional',
            requireLogin: true,
            
        })




        /*-------------  Jobs control Ends -----------------*/


        .when('/faq/', {
            templateUrl: 'ngviews/faq.html',//partails + ctrl
            controller: 'FaqCtrl',
            requireLogin: false,
            
        })


        .when('/contact-us/',{
            templateUrl: 'ngviews/contact_us.html',
            controller: 'ContactUsCtrl',
            requireLogin: false,
            
        })

        .when('/about-us/', {
            templateUrl: 'ngviews/static_page.html',
            controller: 'AboutUsCtrl',
            data: {
                'meta': {
                    'title': 'Training Calendar | Skill development marketplace | Online Courses',
                    'og:title': 'Training Calendar | Skill development marketplace | Online Courses',
                    'og:description': 'Skill enhancement platform that brings together trainers, training providers, recruiters, corporates, individuals, students and executive education providers.Online Courses, Training Providers, Employee Training, Corporate Training Consultant, Best Training Providers',
                }
            },
            requireLogin: false,
            
        })

        .when('/terms-and-conditions/', {
            templateUrl: 'ngviews/static_page.html',
            controller: 'TermsConditionCtrl',
            requireLogin: false,
            
        })
        .when('/privacy-policy/', {
            templateUrl: 'ngviews/static_page.html',
            controller: 'PrivacyPolicyCtrl',
            requireLogin: false,
            
        })
        .when('/terms-of-service/', {
            templateUrl: 'ngviews/static_page.html',
            controller: 'TermsServiceCtrl',
            requireLogin: false,
            
        })
        .when('/page/vendor-policy', {
            templateUrl: 'ngviews/static_page.html',
            controller: 'VendorPolicyCtrl',
            requireLogin: false,
            
        })
        .when('/user/profile/change-mobile-number', {
            templateUrl: 'ngviews/user/update_mobile_number.html',
            controller: 'ChangeMobileCtrl',
            requireLogin: true,
            
        })
        .when('/user/internal/trainers', {
            templateUrl: 'ngviews/il_trainers.html',
            controller: 'TrainersListCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/trainers/add', {
            templateUrl: 'ngviews/il_add_trainer.html',
            controller: 'AddTrainerCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/trainers/edit/:seourl', {
            templateUrl: 'ngviews/il_edit_trainer.html',
            controller: 'EditTrainerCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })

        .when('/user/internal/corporate/trainers', {
            templateUrl: 'ngviews/il_corporate_trainers.html',
            controller: 'PotentialTrainersCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/course/create', {
            templateUrl: 'ngviews/il_create_course.html',
            controller: 'InternalCreateCourseCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/course/activity/:course_id', {
            templateUrl: 'ngviews/il_update_activity.html',
            controller: 'UpdateActivityCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/course/activity/view/:seourl/:activity_id', {
            templateUrl: 'ngviews/il_pdf_viewer.html',
            controller: 'PdfActivityViewer',
            menuType:'internal',
            requireLogin: true,
        })
        .when('/user/internal/course/activity/edit/:seourl/:activity_id', {
            templateUrl: 'ngviews/il_pdf_editor.html',
            controller: 'PdfActivityEditor',
            menuType:'internal',
            requireLogin: true,
        })
        .when('/user/internal/course/video/edit/:course_id/:activity_id', {
            templateUrl: 'ngviews/il_video_editor.html',
            controller: 'VideoActivityEditor',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/course/video/view/:course_id/:activity_id', {
            templateUrl: 'ngviews/il_video_viewer.html',
            controller: 'VideoActivityViewer',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/course/edit/:seourl', {
            templateUrl: 'ngviews/il_edit_course.html',
            controller: 'EditCourseCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/courses', {
            templateUrl: 'ngviews/il_courses.html',
            controller: 'CoursesListCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })

        .when('/user/internal/training-plan/create/:seourl', {
            templateUrl: 'ngviews/il_create_training_plan.html',
            controller: 'CreateTrainingPlanCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/training-plan/edit/:seourl', {
            templateUrl: 'ngviews/il_edit_training_plan.html',
            controller: 'EditTrainingPlanCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/induction-plan/create/:seourl', {
            templateUrl: 'ngviews/il_create_induction_plan.html',
            controller: 'CreateInductionPlanCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/induction-plan/edit/:seourl', {
            templateUrl: 'ngviews/il_edit_induction_plan.html',
            controller: 'EditInductionPlanCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })

        .when('/user/internal/training-plans', {
            templateUrl: 'ngviews/il_training_plans.html',
            controller: 'TrainingPlansListCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/induction-plans', {
            templateUrl: 'ngviews/il_induction_plans.html',
            controller: 'InductionPlansListCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        // 	.when('/user/internal/dashboard', {
        // 	templateUrl: 'ngviews/il_dashboard.html',
        // 	controller: 'InternalDashboardCtrl',
        // 	menuType:'internal',
        // 	requireLogin: true,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })

        .when('/user/internal/dashboard', {
            templateUrl: 'ngviews/il_dashboard_new.html',
            controller: 'InternalDashboardNewCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })

        .when('/user/internal/learning-tracker', {
            templateUrl: 'ngviews/il_learning_tracker.html',
            controller: 'LearningTrackerCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/delegates-tracker/:seourl', {
            templateUrl: 'ngviews/il_delegates_tracker.html',
            controller: 'DelegatesTrackerCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/learning-pathway', {
            templateUrl: 'ngviews/il_learning_pathway.html',
            controller: 'LearningPathwayCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })

        .when('/user/internal/certificates', {
            templateUrl: 'ngviews/il_certificates.html',
            controller: 'CertificatesCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/certificates/upload', {
            templateUrl: 'ngviews/add_employee_certificate.html',
            controller: 'CertificatesUploadCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/certificates/edit', {
            templateUrl: 'ngviews/edit_employee_certificate.html',
            controller: 'EditCertificateCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/quiz/view/:seourl', {
            templateUrl: 'ngviews/il_quiz_view.html',
            controller: 'QuizViewerCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/new/quiz/:seourl', {
            templateUrl: 'ngviews/il_quiz_new.html',
            controller: 'QuizNewCtrl',
            menuType:'internal',
            requireLogin: true,
        })
        .when('/user/internal/new/quiz/:seourl/:activity_id', {
            templateUrl: 'ngviews/il_quiz_new.html',
            controller: 'QuizNewCtrl',
            menuType:'internal',
            requireLogin: true,
        })
        .when('/user/internal/learning/courses', {
            templateUrl: 'ngviews/il_learning_courses.html',
            controller: 'LearningCoursesCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/training/courses/training', {
            templateUrl: 'ngviews/il_tp_training_courses.html',
            controller: 'TPTrainingCoursesCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/training/courses/induction', {
            templateUrl: 'ngviews/il_ip_training_courses.html',
            controller: 'IPTrainingCoursesCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/induction/delegates/:plan_id', {
            templateUrl: 'ngviews/il_induction_delegates.html',
            controller: 'InductionDelegatesCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/trained/delegates', {
            templateUrl: 'ngviews/il_trained_delegates.html',
            controller: 'TrainedDelegatesCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/course-materials/:seourl/:plan', {
            templateUrl: 'ngviews/il_course_materials.html',
            controller: 'CourseMaterial',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/quiz/viewer/:seourl/:courseid/:planid', {
            templateUrl: 'ngviews/il_quiz_viewer.html',
            controller: 'ActivityQuizViewerCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/courses/ratings', {
            templateUrl: 'ngviews/il_internal_courses_ratings.html',
            controller: 'InternalCoursesRatingCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/trainers/ratings', {
            templateUrl: 'ngviews/il_internal_trainers_ratings.html',
            controller: 'InternalTrainersRatingCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/analytics', {
            templateUrl: 'ngviews/il_analytics.html',
            controller: 'AnalyticalCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/hours-spent/:seourl', {
            templateUrl: 'ngviews/il_employees_training_hours.html',
            controller: 'InternalUserHoursCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/training-plans-status', {
            templateUrl: 'ngviews/il_course_plans_status.html',
            controller: 'InternalTPStatusCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/induction-plans-status', {
            templateUrl: 'ngviews/il_course_plans_status.html',
            controller: 'InternalTPStatusCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })

        .when('/cart', {
            templateUrl: 'ngviews/cart.html',
            controller: 'CartCtrl',
            requireLogin: false,
            
        })
/*
        .when('/checkout', {
            templateUrl: 'ngviews/checkout.html',
            controller: 'CheckoutCtrl',
            requireLogin: true,
            resolve: {
                loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        'dist/js/controllers.min.js'
                    ]);
                }]		}
        })

        .when('/checkout/responseHandler', {
            templateUrl: 'ngviews/checkout.html',
            controller: 'CheckoutCtrl',
            requireLogin: false,
            resolve: {
                loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        'dist/js/controllers.min.js'
                    ]);
                }]		}
        })*/


        .when('/user/wishlist', {
            templateUrl: 'ngviews/user/wishlist.html',
            controller: 'WhislistCtrl',
            requireLogin: true
        })

        .when('/slides', {
            templateUrl: 'ngviews/slides.html',
            controller: 'SlideShareCtrl',
            requireLogin: false
        })

        .when('/slide/:slide_id/:q', {
            templateUrl: 'ngviews/pdfViewer.html',
            controller: 'PDFViewer',
            requireLogin: false
        })

        .when('/slide/:slide_id', {
            templateUrl: 'ngviews/pdfViewer.html',
            controller: 'PDFViewer',
            requireLogin: false
        })

        .when('/publictrainer', {
            templateUrl: 'ngviews/public-trainer.html',
            controller: 'TrainersCtrl',
            requireLogin: false
        })


        // 	.when('/user/advanced-courses/:seourl', {
        // 	templateUrl: 'view/individual/advanced_courses',
        // 	controller: 'IndAdvancedCoursesCtrl',
        // 	requireLogin: false,
        // 	resolve: {
        // 		loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
        // 			return $ocLazyLoad.load([
        // 				'dist/js/controllers.min.js'
        // 			])
        // 		}]
        // 	}
        // })

        .when('/user/feedback/dashboard', {
            templateUrl: 'ngviews/fb_dashboard.html',
            controller: 'FeedbackDashboardCtrl',
            menuType:'feedback',
            requireLogin: true,
            
        })

        .when('/user/feedback/external/classroom', {
            templateUrl: 'ngviews/fb_classroom_external.html',
            controller: 'ClassroomFeedbackExternal',
            menuType:'feedback',
            requireLogin: true,
            
        })

        .when('/user/feedback/internal/classroom', {
            templateUrl: 'ngviews/fb_classroom_internal.html',
            controller: 'ClassroomFeedbackInternal',
            menuType:'feedback',
            requireLogin: true,
            
        })

        .when('/user/feedback/internal-expert/classroom', {
            templateUrl: 'ngviews/fb_classroom_internal-expert.html',
            controller: 'ClassroomFeedbackInternalExternal',
            menuType:'feedback',
            requireLogin: true,
            
        })

        .when('/user/feedback/internal/otj', {
            templateUrl: 'ngviews/fb_classroom_otj.html',
            controller: 'OTJFeedbackInternal',
            menuType:'feedback',
            requireLogin: true,
            
        })

        .when('/user/feedback/internal/self', {
            templateUrl: 'ngviews/fb_classroom_self.html',
            controller: 'SelfFeedbackInternal',
            menuType:'feedback',
            requireLogin: true,
            
        })

        .when('/user/feedback/internal/specific', {
            templateUrl: 'ngviews/fb_classroom_specific.html',
            controller: 'SpecificFeedbackInternal',
            menuType:'feedback',
            requireLogin: true,
            
        })

        .when('/user/feedback/external/publish', {
            templateUrl: 'ngviews/fb_classroom_publish_list.html',
            controller: 'PublishListFeedbackInternal',
            menuType:'feedback',
            requireLogin: true,
            
        })
        .when('/user/feedback/external/publish/create', {
            templateUrl: 'ngviews/fb_classroom_publish_create.html',
            controller: 'PublishCreateFeedbackInternal',
            menuType:'feedback',
            requireLogin: true,
            
        })
        .when('/user/internal/training/feedback/delegates', {
            templateUrl: 'ngviews/fb_internal_delegate_courses.html',
            controller: 'InternalFeedbackDelegateCourses',
            requireLogin: true,
        })

        .when('/user/external/training/feedback/delegates', {
            templateUrl: 'ngviews/fb_external_delegate_courses.html',
            controller: 'ExternalFeedbackDelegateCourses',
            requireLogin: true,
        })

         .when('/user/internal/trainer/feedback/delegates', {
            templateUrl: 'ngviews/il_feedback_trainer_courses.html',
            controller: 'FeedbackTrainerCourses',
            menuType:'internal',
            requireLogin: true,
            
        })

        .when('/user/internal/training/feedback/update/:plan_id', {
            templateUrl: 'ngviews/il_feedback_update.html',
            controller: 'FeedbackUpdateCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })

		.when('/user/training/delegate-feedback/update/:plan_id', {
            templateUrl: 'ngviews/il_delegate_feedback_update.html',
            controller: 'DelegateFeedbackUpdateCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })

        .when('/user/feedback/overview', {
            templateUrl: 'ngviews/fb_overview.html',
            controller: 'FeedbackOverviewCtrl',
            menuType:'feedback',
            requireLogin: true,
            resolve: {
                loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
                    return $ocLazyLoad.load([
                       'dist/js/controllers.min.js'
                    ]);
                }]
            }
        })

        .when('/user/attendence/overview', {
            templateUrl: 'ngviews/fb_attendence_overview.html',
            controller: 'AttendenceOverviewCtrl',
            menuType:'feedback',
            requireLogin: true,
            resolve: {
                loadAsset: ['$ocLazyLoad', function($ocLazyLoad) {
                    return $ocLazyLoad.load([
                       'dist/js/controllers.min.js'
                    ]);
                }]
            }
        })
        .when('/user/feedback/overview/:plan_id', {
            templateUrl: 'ngviews/fb_plan_overview.html',
            controller: 'FeedbackPlanOverview',
            menuType:'feedback',
            requireLogin: true,
            
        })
        .when('/user/internal/training/attendence/courses', {
            templateUrl: 'ngviews/il_attendence_delegate_courses.html',
            controller: 'AttendenceDelegateCourses',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/internal/training/attendance/update/:plan_id', {
            templateUrl: 'ngviews/il_attendence_update.html',
            controller: 'AttendenceUpdateCtrl',
            menuType:'internal',
            requireLogin: true,
            
        })
        .when('/user/gift-courses', {
            templateUrl: 'ngviews/gift_courses.html',
            controller: 'GiftCourses',
            requireLogin: true,
            
        })
         .when('/user/gifted-courses', {
            templateUrl: 'ngviews/gifted_courses.html',
            controller: 'GiftedCourses',
            requireLogin: true,
            
        })
         .when('/checkout/progress/:status', {
          templateUrl: 'ngviews/checkout-progress.html',
          controller: 'CheckoutResponseCtrl'
        })
        .when('/order/success', {
		  templateUrl: 'ngviews/order_success.html',
          controller: 'CheckoutStatusCtrl'
	 	})
		 .when('/order/failure', {
		  templateUrl: 'ngviews/order_failure.html',
          controller: 'CheckoutStatusCtrl'
	 	})
         .when('/user/notifications', {
          templateUrl: 'ngviews/notification.html',
          requireLogin: true,
          controller: 'NotificationsCtrl'
        });

    // .otherwise({ redirectTo: '/home', requireLogin: false, });
    $locationProvider.html5Mode(true).hashPrefix('!');
    // $locationProvider.html5Mode(true);
}]);

app.config(function($httpProvider){
    delete $httpProvider.defaults.headers.common['X-Requested-With'];
});



app.config(['$qProvider', function ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
}]);



app.controller('footerCtrl',['$localStorage','$scope','$http','$location','$rootScope','$cookies','$timeout', function ($localStorage,$scope,$http,$location, $rootScope,$cookies,$timeout) {
    $scope.SignUp = function(userType){
        $('#signup_modal').modal('show');
        $('.modal').not('#signup_modal').modal('hide');
        if(userType){
            $('select[name=userType]').selectpicker('val',userType);
            $('select[name=userType]').trigger('change');
        }
    };
}]);

app.controller('EnquiryCtrl',['$localStorage','$scope','$http','$location','$rootScope','$cookies', function ($localStorage,$scope,$http,$location, $rootScope,$cookies) {
    $scope.submitEnquiryForm = function(enquiry, seourl, type){
        $('.loading-overlay').show();
        $scope.loaded = true;
        $http({
            method: 'POST',
            url: 'api/account/enquiry',
            headers: {'Content-Type': undefined},
            data: {enquiry: enquiry, seourl:seourl, type:type}
        }).then(function (resp, status) {
            if(resp.data.status == 200){
                swal("",resp.data.message,"success");
            }else{
                swal("",resp.data.message,"error");
            }

            $scope.loaded = false;
            $rootScope.closeDialog();
            $('.loading-overlay').hide();
        });
    };
}]);

app.controller('AgreeCtrl',['$localStorage','$scope','$http','$location','$rootScope','$cookies', function ($localStorage,$scope,$http,$location, $rootScope,$cookies) {

    // $('#agree_modal').modal('show');
    $scope.iAgree = function()
    {
        $http({

            method: 'POST',
            url: 'api/account/agree_to_terms',
            headers: {'Content-Type': undefined},
            data: {userData: $rootScope.userData}
        }).then(function (response, status) {
            // $('.modal').modal('hide');
            $rootScope.closeDialog();
            //$location.path('/user/dashboard');
            $rootScope.tempData = response.data.temp;
            $localStorage.tempData = $rootScope.tempData;
        });
    };

}]);

app.controller('subscribeCtrl',['$localStorage','$scope','$http','$location','$rootScope','$cookies','$route', function ($localStorage,$scope,$http,$location, $rootScope,$cookies,$route) {

    $scope.subscribeMe = function($subscribe_mail){
        $scope.sub_loaded = true;
        $scope.sub_submitted = false;
        var fd = new FormData();
        fd.append('email',$subscribe_mail);

        $http({
            method: 'POST',
            url: 'api/account/subscribe',
            headers: {'Content-Type': undefined},
            transformRequest: angular.identity,
            data: fd
        })
            .then(function (resp, status) {
                $scope.sub_loaded = null;
                swal("Success",resp.data.message,"success");
                $scope.reset();
            });
    };
}]);

app.controller('leftmenuController',function ($localStorage,$scope,$http,$location, $rootScope,$cookies,$route,ngDialog) {

    $scope.$on('ngDialog.closing', function () {
        angular.element('#input_profile_pic').val(null);
    });
    if($scope.userData.user_type_id == 2 || $scope.userData.user_type_id == 3 || $scope.userData.user_type_id == 8){
        $scope.profileCrop = 'ngviews/imgCrop/circleCrop.html';
    }
    else{
        $scope.profileCrop = 'ngviews/imgCrop/rectCrop.html';
    }

    var ShowNgDialog = function() {
        var dialog = ngDialog.open({
            template: $scope.profileCrop,
            showClose: false,
            scope: $scope,
            keyboard: false,
            closeByEscape: false,
            closeByDocument: false,
            closeByNavigation: true
        });
    };

    $scope.fileChanged = function(evt) {
        var file=evt.currentTarget.files[0];
        var reader = new FileReader();
        reader.onload = function (evt) {
            $scope.$apply(function($scope){
                $scope.myImage=evt.target.result;
            });

        };
        reader.readAsDataURL(file);
        ShowNgDialog();
    };
});

app.run(function ($rootScope, $window, ngDialog, $http, $localStorage,$timeout,$templateCache,$location, $routeParams,tcAjax, tcCommon, $translate, $interval) {

    $rootScope.isDevelopment = true;
    $rootScope.uploads_path = 'uploads/';
    $rootScope.uploads_img = 'uploads/images/';
    $rootScope.uploads_brochures = 'uploads/brochures/';
    $rootScope.uploads_charts = 'uploads/charts/';
    $rootScope.uploads_flyers = 'uploads/flyers/';
    $rootScope.uploads_videos = 'uploads/videos/';

    $rootScope.dist_path = 'dist/';
    $rootScope.dist_img = 'dist/images/';
    $rootScope.dist_explanatory = 'dist/explanatory/';
    $rootScope.dist_templates = 'dist/templates/';
    $rootScope.dist_videos = 'dist/videos/';


    $rootScope.changeLanguage = function (langKey) {
        $translate.use(langKey);
    };

    // var url = 'api/helper/get_ip_address';
    // var params = {name: 'get_ip_address'};
    // tcAjax.http(params,url,false,
    // 	function(resp){
    // 		if(resp.ip == '49.206.124.135'){
    // 			$rootScope.isDevelopment = true;
    // 		}
    // 	},
    // 	function(error) { }
    // );


    // ngMeta.init();
    $rootScope.isMoved = '0'; // for organisation chart
    $rootScope.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); //to detect if mobile

    $rootScope.CourseFeedback = function () {
        ngDialog.closeAll();
        var dialog = ngDialog.open({
            template: 'ngviews/ngDialog/feedback_dialog.html',
            showClose: false,
            keyboard: false,
            closeByEscape: false,
            closeByDocument: false,
            closeByNavigation: true
        });
    };

    // $rootScope.pageName = $location.absUrl().split('?')[0];
    // console.log($rootScope.pageName);

    $rootScope.currentPath = $location.path().split("/").splice(-1)[0];

    $rootScope.profileComplete = function() {
        ngDialog.closeAll();
        var dialog = ngDialog.open({
            template: 'ngviews/ngDialog/profile-complete.html',
            showClose: false,
            keyboard: true,
            closeByEscape: true,
            closeByDocument: true,
            closeByNavigation: true
        });
        return;
    };

    $rootScope.ShowApplySignUp = function () {
        $('.modal').modal('hide');
        $('#apply_signup_modal').modal('show');
    };


    $rootScope.verifyEmail = function(email, user_type_id){
        tcCommon.verifyEmail(email,function (response) {
            if (response.data.status === 302) {
                $rootScope.emailExists = true;
            }
            else if (response.data.status === 200) {
                $rootScope.emailExists = false;
            }
        });
        if(user_type_id == 4 || user_type_id == 9){
            $rootScope.emailProviderExists = false;
            tcCommon.verifyCompanyEmail(email,function (response) {
                if (response.data.status === 302) {
                    $rootScope.emailProviderExists = true;
                }
                else if (response.data.status === 200) {
                    $rootScope.emailProviderExists = false;
                }
            });
        }
    };

    $rootScope.verifyMobile = function(mobile){
        tcCommon.verifyMobile(mobile,function (response) {
            if (response.data.status === 302) {
                $rootScope.mobileExists = true;
            }
            else if (response.data.status === 200) {
                $rootScope.mobileExists = false;
            }
        });
    };


    $rootScope.verifyLandline = function(landline){
        tcCommon.verifyLandline(landline,function (response) {
            if (response.data.status === 302) {
                $rootScope.landlineExists = true;
            }
            else if (response.data.status === 200) {
                $rootScope.landlineExists = false;
            }
        });
    };

    $rootScope.sendOTP = function(dial_code,mobile_number){
        if(!$rootScope.mobileExists){
            $rootScope.otp_incorrect = false;
            $rootScope.loading = true;
            tcCommon.SendOTP(dial_code,mobile_number,function (response) {
                // $scope.signup.otp = '';
                $rootScope.loading = false;
                if(response.data.status == 200){
                    $rootScope.otp_sent = true;
                }
                else{
                    $rootScope.otp_sent = false;
                    swal('',response.data.message,response.data.type);
                }
            });
        }
    };

    $rootScope.verifyOTP = function(dial_code,mobile_number,otp){
        $rootScope.otp_incorrect = false;
        if(otp !== undefined && otp.length >= 4){
            $rootScope.loading = true;
            tcCommon.verifyOTP(dial_code,mobile_number,otp,function (response) {
                $rootScope.loading = false;
                if(response.data.status == 200){
                    $rootScope.otp_verified = true;
                    $rootScope.otp_incorrect = false;
                }
                else{
                    $rootScope.otp_verified = false;
                    $rootScope.otp_incorrect = true;
                }
            });
        }
    };

    $rootScope.ShowSignUpSelection = function () {
        ngDialog.closeAll();
        $location.path('signup');
    };

    $rootScope.ShowDemo = function () {
        ngDialog.closeAll();
        var dialog = ngDialog.open({
            template: 'ngviews/account/demo.html',
            showClose: false,
            keyboard: false,
            closeByEscape: false,
            closeByDocument: false,
            closeByNavigation: true
        });
    };
    // if($routeParams.from == 'linkedin'){
    // 	$rootScope.ShowSignUp();
    // 	console.log($routeParams.from);
    // }

    $rootScope.ShowForgotPass = function () {
        $('.modal').modal('hide');
        ngDialog.closeAll();
        var dialog = ngDialog.open({
            template: 'ngviews/account/forgotpass.html',
            showClose: false,
            keyboard: false,
            closeByEscape: false,
            closeByDocument: false,
            closeByNavigation: true
        });
    };

    $rootScope.ShowTerms = function () {
        ngDialog.closeAll();
        var dialog = ngDialog.open({
            template: 'ngviews/ngDialog/agree_dialog.html',
            showClose: false,
            keyboard: false,
            closeByEscape: false,
            closeByDocument: false,
            closeByNavigation: true
        });
    };

    $rootScope.scrollTop = function () {
        $("html, body").stop().animate({scrollTop:0}, 500, 'swing', function() { });
    };


    $rootScope.closeDialog = function () {
        ngDialog.closeAll();
    };

    $rootScope.openSearch = function(){
        $('#search_modal').modal('show');
    };

    $rootScope.openSlideSearch = function(){
        $('#slide_search_modal').modal('show');
    };

    var d = new Date();
    var n = d.getSeconds();
    $rootScope.seconds = n;


    // $rootScope.siteData = {};
    // if(angular.equals({}, $rootScope.siteData))
    // {
    $http({
        method: 'post',
        url: 'api/user/get_sitedata',
        headers: {'Content-Type': 'application/json'},
        // data: {userData: $rootScope.userData},
    }).then(function(resp,status) {
        $rootScope.siteData = resp.data.sitedata;
        $rootScope.all_countries = resp.data.sitedata.countries;
        $rootScope.all_specializations = resp.data.sitedata.specializations;
        $rootScope.all_industries = resp.data.sitedata.industries;
        // $rootscope.NotificationsCount = resp.data.NotificationsCount;
    });
    // }
    // $rootScope.CourseFeedback();

    $rootScope.ShowSEnquiryForm = function (title, seourl, type) {
        ngDialog.closeAll();
        var dialog = ngDialog.open({
            template: 'ngviews/enquiry.html',
            showClose: false,
            data: {
                'title': title,
                'seourl': seourl,
                'type':type
            },
            keyboard: false,
            closeByEscape: false,
            closeByDocument: false,
            closeByNavigation: true
        });
    };

    $rootScope.ShowCoursePreRegisterForm = function (course) {
        ngDialog.closeAll();
        var dialog = ngDialog.open({
            template: 'ngviews/registrations/course_preregister.html',
            showClose: false,
            data: {
                'course': course,
            },
            keyboard: false,
            closeByEscape: false,
            closeByDocument: false,
            closeByNavigation: true
        });
    };

    $rootScope.ShowEnquiryForm = function (about, type) {
        ngDialog.closeAll();
        var dialog = ngDialog.open({
            template: 'ngviews/enquiry.html',
            showClose: false,
            data: {
                'about': about,
                'type': type,
            },
            keyboard: false,
            closeByEscape: false,
            closeByDocument: false,
            closeByNavigation: true
        });
    };

    $rootScope.ShowCourseRegister = function (course) {
        if(!$rootScope.userData.authenticated)
        {
            ngDialog.closeAll();
            $rootScope.ShowSignIn();
        }else{
            ngDialog.closeAll();
            var dialog = ngDialog.open({
                template: 'ngviews/course_register.html',
                showClose: false,
                data: {
                    'course': course,
                    'courseRegister': $rootScope.userData,
                },
                keyboard: false,
                closeByEscape: false,
                closeByDocument: false,
                closeByNavigation: true
            });
        }
    };

    /* Cart Checkout Start */

    $rootScope.checkCartAndAdd = function(seo_url) {
        var found = $localStorage.cartData.some(function (el) {
            return el.itemId === seo_url;
        });
        if (!found) {
            return false;
        } else {
            return true;
        }
    };

    $rootScope.gotoCart = function()
    {
        $location.path('cart');
    };

    $rootScope.gotoCheckOut = function()
    {
        $location.path('checkout');
    };


    /* Cart Checkout End */

    $rootScope.ShowProposalRegister = function (proposal,currencies) {
        $timeout(function(){
            $('select').selectpicker('refresh');
        }, 50);
        ngDialog.closeAll();
        if(!$rootScope.userData.authenticated)
        {
            $rootScope.ShowSignIn();
        }else{
            var dialog = ngDialog.open({
                template: 'ngviews/ngDialog/proposal_register.html',
                showClose: false,
                data: {
                    'proposal': proposal,
                    'currencies': currencies
                },
                keyboard: false,
                closeByEscape: false,
                closeByDocument: false,
                closeByNavigation: true
            });
        }
    };



    (function () {
        'use strict';

        var module = {
            options: [],
            header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
            dataos: [
                { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
                { name: 'Windows', value: 'Win', version: 'NT' },
                { name: 'iPhone', value: 'iPhone', version: 'OS' },
                { name: 'iPad', value: 'iPad', version: 'OS' },
                { name: 'Kindle', value: 'Silk', version: 'Silk' },
                { name: 'Android', value: 'Android', version: 'Android' },
                { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
                { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
                { name: 'Macintosh', value: 'Mac', version: 'OS X' },
                { name: 'Linux', value: 'Linux', version: 'rv' },
                { name: 'Palm', value: 'Palm', version: 'PalmOS' }
            ],
            databrowser: [
                { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
                { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
                { name: 'Safari', value: 'Safari', version: 'Version' },
                { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
                { name: 'Opera', value: 'Opera', version: 'Opera' },
                { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
                { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
            ],
            init: function () {
                var agent = this.header.join(' '),
                    os = this.matchItem(agent, this.dataos),
                    browser = this.matchItem(agent, this.databrowser);

                return { os: os, browser: browser };
            },
            matchItem: function (string, data) {
                var i = 0,
                    j = 0,
                    html = '',
                    regex,
                    regexv,
                    match,
                    matches,
                    version;

                for (i = 0; i < data.length; i += 1) {
                    regex = new RegExp(data[i].value, 'i');
                    match = regex.test(string);
                    if (match) {
                        regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                        matches = string.match(regexv);
                        version = '';
                        if (matches) { if (matches[1]) { matches = matches[1]; } }
                        if (matches) {
                            matches = matches.split(/[._]+/);
                            for (j = 0; j < matches.length; j += 1) {
                                if (j === 0) {
                                    version += matches[j] + '.';
                                } else {
                                    version += matches[j];
                                }
                            }
                        } else {
                            version = '0';
                        }
                        return {
                            name: data[i].name,
                            version: parseFloat(version)
                        };
                    }
                }
                return { name: 'unknown', version: 0 };
            }
        };

        var e = module.init(),
            debug = '';

        if(!$localStorage.browserMsg)
        {
            /*if((e.browser.name == 'Chrome' && e.browser.version <= 6) || (e.browser.name == 'Firefox' && e.browser.version) || (e.browser.name == 'Internet Explorer' && e.browser.version) || (e.browser.name == 'Safari' && e.browser.version))
			{
				// ngDialog.closeAll();
				// var dialog = ngDialog.open({
				// 	template: 'ngviews/ngDialog/browser_dialog.html',
				// 	showClose: false,
				// 	keyboard: false,
				// 	closeByEscape: false,
				// 	closeByDocument: false,
				// 	closeByNavigation: true
				// });

				$localStorage.browserMsg =  true;
			}*/

            if(e.browser.name == 'Safari' && e.browser.version < 9)
            {
                swal('Your Browser is not Supported!','Please use Safari version 10 or greater','info');

                $localStorage.browserMsg =  true;

            }
        }
    }());

    $rootScope.$on('$routeChangeStart', function(event, next, current) {
        $('.loading-overlay').hide();
        if (typeof(current) !== 'undefined' && typeof(next) !== 'undefined'){
            if(next.templateUrl == 'ngviews/blog/create_post.html' || next.templateUrl == 'ngviews/blog/my-posts.html')
            {
                if($rootScope.userData && $rootScope.userData.authenticated){

                }
                else{
                    event.preventDefault();
                    $rootScope.ShowSignIn();
                }
            }
        }

        /*Plan subscription Check Starts*/
        if(next.$$route && next.$$route.requireLogin === true && $rootScope.userData && $rootScope.userData.user_type_id === '4'){
            $rootScope.plan_active = true;
            var url = 'api/user/get_subscription';
            var param = JSON.stringify({userData:$localStorage.userData});
            tcAjax.http(param,url,false,
                function(resp){
                    // console.log(resp);
                    $rootScope.plan_active = resp.plan_active;
                    $rootScope.subscriptions = resp.subscription;
                    if(!resp.plan_active){
                        // $location.path('/signout');
                        $rootScope.signOut();
                    }
                },
                function(error) { }
            );
        }
        /*Plan subscription Check Ends*/

        //       if(next.$$route && next.$$route.requireLogin == true && $rootScope.userData && $rootScope.userData.user_type_id == '4' && !$rootScope.plan_active){
        // 	$location.path('/');
        // 	swal('Subscription Expired','Your subscription has Expired, Please subscribe any Plan to continue ','info');
        // }

        if($rootScope.userData && $rootScope.userData.authenticated)
        {
            $rootScope.notify_email = $rootScope.userData.email;
        }
        // console.log(next.$$route);
        if(next.$$route !== undefined){
            $rootScope.menuType = next.$$route.menuType;
        }

        if(current){
            if(current.$$route.controller == 'CorpHirchlChartCtrl'){
                $('body').find('.dashboard-right').removeClass('full-screen');
                $('body').find('.dashboard-left').removeClass('full-screen');
            }
        }

        /*start disable feature*/
        if($rootScope.userData && $rootScope.userData.authenticated){
            $rootScope.isILmenu = false;
            $rootScope.isFBmenu = false;


            $rootScope.disableIL = false;
            $rootScope.disableCV = false;
            $rootScope.disableAddUser = false;
            $rootScope.disableMsg = 'Contact Training Calendar Team for further support';
            $rootScope.disableRedirect = '/user/dashboard';
            $rootScope.disableTitle = 'Disabled';
            if($rootScope.userData.user_id === ''){
                $rootScope.disableIL = true;
            }
            if($rootScope.userData.user_id === ''){
                $rootScope.disableCV = true;
            }
            if($rootScope.userData.user_id === ''){
                $rootScope.disableAddUser = true;
            }

            // console.log($rootScope.menuType);
            if($rootScope.menuType == 'internal'){
                $rootScope.isILmenu = true;
                if($rootScope.disableIL){
                    $rootScope.isILmenu = false;
                    swal($rootScope.disableTitle, $rootScope.disableMsg, "info");
                    $location.path($rootScope.disableRedirect);
                }
            }
            else if($rootScope.menuType == 'feedback'){
                $rootScope.isFBmenu = true;
            }
        }
        /*end disable feature*/

    });

    $rootScope.ShowGuide = function() {
        ngDialog.closeAll();
        var dialog = ngDialog.open({
            template: 'ngviews/ngDialog/guide.html',
            showClose: false,
            keyboard: false,
            closeByEscape: false,
            closeByDocument: false,
            closeByNavigation: true
        });
    };

    /*Start job match wieghtage */
    $rootScope.getWeightageChart = function(user_id, seo_url, title_name){
        tcCommon.getWeightageChart(user_id, seo_url, title_name);
    }
    /*End job match wieghtage */

    $rootScope.signOut = function(){
        var url = 'api/account/logout';
        var param = JSON.stringify({userData :$rootScope.userData});
        tcAjax.http(param,url,true,
            function(resp){
                if (resp.status === 200) {
                    swal('Logged out successfully!',resp.message,'success');
                    $rootScope.userData = {};
                    $localStorage.userData = {};
                    $location.path('/');
                }
                else{
                    swal('',resp.message,'info');
                }
                
            },
            function(error) { }
        );
    }


    $interval( function(){ 
        if($rootScope.userData && $rootScope.userData.authenticated){
            // $rootScope.getNotificationsCount();
            $rootScope.getRecentNotifications();
        } 
    }, 6000);

    
    // $rootScope.getNotificationsCount = function(){
    //     var url = 'api/notifications/getNotificationsCount';
    //     var params = JSON.stringify({userData: $rootScope.userData});
    //     tcAjax.http(params,url,false,
    //         function(resp){
    //             if(resp.status == 200){
    //                 $rootScope.NotificationsCount = resp.count;
    //             }
    //             else{
    //                 // swal("Try Again!", resp.message, resp.type);
    //             }
    //         },
    //         function(error) { }
    //     );
    // }


    $rootScope.getRecentNotifications = function(){
        var url = 'api/notifications/getRecentNotifications';
        var params = JSON.stringify({userData: $rootScope.userData});
        tcAjax.http(params,url,false,
            function(resp){
                if(resp.status == 200){
                    $rootScope.RecentNotifications = resp.notifications;
                    $rootScope.NotificationsCount = resp.count;
                }
                else{
                    // swal("Try Again!", resp.message, resp.type);
                }
            },
            function(error) { }
        );
    }


});

app.controller('CourseRegisterCtrl', ['$localStorage','$sessionStorage','$scope','$http','$location','$rootScope','$window','AuthenticationService','$timeout','ngDialog', function ($localStorage,$sessionStorage,$scope,$http,$location, $rootScope,$window,AuthenticationService,$timeout,ngDialog) {
    $scope.submitCourseRegister = function(seo_url, tickets) {
        $('.loading-overlay').show();
        $scope.process = true;
        $http({
            method: 'post',
            url: 'api/courses/register_course',
            headers: {'Content-Type': 'application/json'},
            data: {user_id: $rootScope.userData.user_id, seo_url: seo_url, tickets:tickets},
        }).then(function(response,status) {
            $('.loading-overlay').hide();
            if (response.data.status === 200) {
                $scope.process = false;
                $rootScope.closeDialog();
                $rootScope.invoice_id = response.data.invoice_id;
                $location.path('/course/register/success/'+response.data.invoice_id);
            }
            else
            {
                swal('', response.data.message,response.data.type);
            }
        });
    };
}]);


app.controller('ConferenceRegisterCtrl', ['$localStorage','$sessionStorage','$scope','$http','$location','$rootScope','$window','AuthenticationService','$timeout','ngDialog', function ($localStorage,$sessionStorage,$scope,$http,$location, $rootScope,$window,AuthenticationService,$timeout,ngDialog) {
    $scope.submitConferenceRegister = function(seo_url, tickets) {
        $('.loading-overlay').show();
        $scope.process = true;
        $http({
            method: 'post',
            url: 'api/conferences/register_conference',
            headers: {'Content-Type': 'application/json'},
            data: {user_id: $rootScope.userData.user_id, seo_url: seo_url, tickets:tickets},
        }).then(function(response,status) {
            $('.loading-overlay').hide();
            if (response.data.status === 200) {
                $scope.process = false;
                $rootScope.closeDialog();
                $rootScope.invoice_id = response.data.invoice_id;
                $location.path('/conference/register/success/'+response.data.invoice_id);
            }
            else
            {
                swal('', response.data.message,response.data.type);
            }
        });
    };
}]);


app.controller('ProposalRegisterCtrl', ['$localStorage','$sessionStorage','$scope','$http','$location','$rootScope','$window','AuthenticationService','$timeout','ngDialog', function ($localStorage,$sessionStorage,$scope,$http,$location, $rootScope,$window,AuthenticationService,$timeout,ngDialog) {
    $scope.submitProposalRegister = function(proposal) {
        $('.loading-overlay').show();
        $scope.process = true;
        $('.loading-overlay').show();
        var fd = new FormData();
        var cv = document.getElementById('cv').files[0];
        fd.append('cv',cv);
        fd.append('proposal',JSON.stringify(proposal));
        fd.append('userData',JSON.stringify($rootScope.userData));
        $http({
            method: 'POST',
            url: 'api/proposals/apply',
            headers: {'Content-Type': undefined},
            transformRequest: angular.identity,
            data: fd
        }).then(function(response,status) {
            $('.loading-overlay').hide();
            if(resp.data.status == 200)
            {
                swal('',resp.data.message,'success');
                $scope.process = false;

                angular.element('#register-modal').modal('hide');
                $scope.rfpApplyForm = {};
                $scope.$watch(function() {
                    $scope.isApplied = true;
                    //$scope.proposal.can_apply = false;
                    //$scope.proposal.already_registered = true;
                });
            }
            else{
                swal('Try Again!',resp.data.message,'error');
            }
        });
    };
}]);


app.controller('SigninCtrl', ['$localStorage','$sessionStorage','$scope','$http','$location','$rootScope','$window','AuthenticationService','$timeout','ngDialog', function ($localStorage,$sessionStorage,$scope,$http,$location, $rootScope,$window,AuthenticationService,$timeout,ngDialog) {

    $scope.inputType = 'password';
    $scope.hideShowPassword = function(){
        if ($scope.inputType == 'password')
            $scope.inputType = 'text';
        else
            $scope.inputType = 'password';
    };

    $rootScope.ShowSignIn = function (id,type) {
        $('.modal').modal('hide');
        $('#signin_modal').modal('show');
        if(id){
            $rootScope.signup_id = id;
            $rootScope.signup_type = type;
            $rootScope.signup_direct = false;
        }
        else{
            $rootScope.signup_id = id;
            $rootScope.signup_type = type;
            $rootScope.signup_direct = true;
        }
    };

    $rootScope.QuickApply = function(id,type){
        $rootScope.QuickApplyJob(id,type);
    };

    $scope.loginRedirect = function(userType){
        if($rootScope.activetab == '/signup/'){
            if(userType != 4){
                $location.path('user/dashboard');
            }
            else{
                $location.path('user/corporate-dashboard');
            }
        }
        else{
            $location.path($rootScope.activetab);
            $('.modal').modal('hide');
        }
    };

    $scope.submitSigninForm = function () {
        $('.loading-overlay').show();
        $scope.loaded = true;
        $http.post('api/account/signinPost', JSON.stringify({username:$scope.signin.username, password:$scope.signin.password}) )
            .then(function (response) {
                $('.loading-overlay').hide();
                $scope.loaded = false;
                if(response.data.status == 200){
                    if(response.data.user)
                    {
                        $rootScope.userData = response.data.user;
                        $scope.userData = response.data.user;
                        $localStorage.userData = $rootScope.userData;


                        $rootScope.profile_complete = response.data.profile_complete;
                        $localStorage.profile_complete = $rootScope.profile_complete;
                        if($rootScope.profile_complete.profile_percentage < 100){
                            // $rootScope.profileComplete();
                        }

                        // alert($rootScope.profile_complete.mandatory_fields_filled);
                        if($rootScope.profile_complete.mandatory_fields_filled){
                            // console.log(mandatory_fields_filled);
                            // alert('in');
                            if($scope.userData.user_type_id == 5 || $scope.userData.user_type_id == 6){
                                if($rootScope.profile_complete.policy < 2){
                                    $location.path('user/policies');
                                }
                                else{
                                    // $location.path('user/dashboard');
                                    $scope.loginRedirect($scope.userData.user_type_id);
                                }
                            }
                            else if($scope.userData.user_type_id == 2 && $scope.userData.user_type_id == 8){
                                if($rootScope.profile_complete.user_skills_updated === true){
                                    // $location.path('user/dashboard');
                                    $scope.loginRedirect($scope.userData.user_type_id);
                                }
                                else{
                                    $location.path('user/skills/update');
                                }
                            }
                            else if($scope.userData.user_type_id == 4){
                                // $location.path('user/corporate-dashboard');
                                $scope.loginRedirect($scope.userData.user_type_id);
                            }
                            else
                            {
                                // $location.path('user/dashboard');
                                $scope.loginRedirect($scope.userData.user_type_id);
                            }

                            /*else if($scope.userData.user_type_id == 2)
						{
							$location.path('user/skills/update');
						}*/

                        }
                        else{
                            $location.path('user/profile/edit');
                        }

                        $scope.signin = [];
                        $rootScope.tempData = response.data.temp;
                        $localStorage.tempData = $rootScope.tempData;
                        if(!$rootScope.tempData.agreed_to_terms)
                        {
                            // agree_modal
                            $rootScope.ShowTerms();
                        }
                        else if(response.data.user.profile_completed == 1)
                        {
                            $scope.closeThisDialog();
                            // $location.path('user/dashboard');
                        }
                        else
                        {
                            $scope.closeThisDialog();
                            // $location.path('user/profile/edit');
                            // $location.path('user/dashboard');
                            // setTimeout(function(){
                            // 	swal('Profile Incomplete!','Please fill up the profile details','info')
                            // },500);
                        }
                    }
                }
                else if(response.data.status == 302)
                {
                    /*swal({title:'',text:response.data.message,confirmButtonText: 'click here to send verification email again!', closeOnConfirm: false,type:'info',allowOutsideClick:false },
					 function(isConfirm) {
					if (isConfirm) {

						$http({
							method: 'post',
							url: 'api/account/resend_verify_mail',
							headers: {'Content-Type': 'application/json'},
							data: {signup: $scope.signup}
						}).then(function(resp,status) {
							swal('','Verification mail sent. Still not received the email, please contact our support team.','info')
							// $location.path('/home');
						});
					}
				});	*/
                    swal({
                        title:'',
                        text:response.data.message,
                        confirmButtonText: 'Resend verification email', closeOnConfirm: false,
                        type:'info',
                        allowOutsideClick:true
                    },function(isConfirm) {
                        if (isConfirm) {
                            $('.loading-overlay').show();
                            $http({
                                method: 'post',
                                url: 'api/account/resend_verify_mail',
                                headers: {'Content-Type': 'application/json'},
                                data: JSON.stringify({email: $scope.signin.username})
                            }).then(function(resp,status) {
                                swal('','Verification mail sent. Still not received the email, please contact our support team.','info');
                                // $location.path('/home');
                                $('.loading-overlay').hide();
                            });
                        }
                    });

                }
                else if(response.data.status == 502)
                {
                    $scope.closeThisDialog();
                    swal({
                        title:'Thank you for registering on Training Calendar.',
                        text:response.data.message,
                        confirmButtonText: 'Close', closeOnConfirm: false,
                        type:'info',
                        allowOutsideClick:true
                    });
                    // ngDialog.closeAll();
                    //       var dialog = ngDialog.open({
                    //           template: 'ngviews/ngDialog/deactivate_dialog.html',
                    //           showClose: false,
                    //           keyboard: false,
                    //           closeByEscape: false,
                    //           closeByDocument: false,
                    //           closeByNavigation: true,
                    //           data: {
                    //           	'login_message': response.data.message
                    //          	}
                    //       });
                }
                else if(response.data.status == 503){
                    swal('','Your trial access has expired. Please contact Training Calendar team to purchase user licenses ','info');
                }
                else{
                    swal({title:'',text:response.data.message,type:'info',allowOutsideClick:false });
                }

            });
    };
}]);


app.controller('SignUpCtrl', function ($localStorage,$sessionStorage,$scope,$http,$location, $rootScope,$window,AuthenticationService,$timeout,ngDialog,$routeParams,tcCommon) {
    // console.log($rootScope.userData.authenticated);
    // if(!angular.equals({}, $scope.userData))
    // {
    // 	$location.path('/home');
    // }
    $scope.susubmitted=false;

    $scope.signup = {};
    $scope.inputType = 'password';
    // $scope.user_type = ['Professional', 'Trainer', 'Corporate', 'Training Provider', 'Executive Education Provider', 'Student/Graduate', 'Recruiter', 'University'];
    $rootScope.otp_sent = false;
    $rootScope.otp_verified = false;
    $rootScope.otp_incorrect = false;
    $rootScope.emailExists = false;
    $rootScope.mobileExists = false;
    $scope.userTypes = {};
    $rootScope.direct_signup = true;

    // if(angular.equals({}, $scope.userTypes))
    // 	{
    // 	$http({
    // 		method: 'post',
    // 		url: 'api/account/getUserTypes',
    // 		headers: {'Content-Type': 'application/json'},
    // 	})
    // 	.then(function(resp,status) {
    // 		$scope.userTypes = resp.data.userTypes;
    // 		$timeout(function(){
    // 			if($routeParams.type){
    // 			}
    // 		},500)
    // 	});
    // }

    $scope.hideShowPassword = function(){
        if ($scope.inputType == 'password')
            $scope.inputType = 'text';
        else
            $scope.inputType = 'password';
    };

    $rootScope.ShowSignUp = function (id, type) {
        $scope.signup = {};
        if(id){
            $rootScope.signup_id = id;
            $rootScope.signup_type = type;
            $rootScope.signup_direct = false;
            $rootScope.user_type_id = '';
            if(type == 'job-apply'){
                $rootScope.signup_title = 'You are applying for job';
                $rootScope.signup_btn_text = 'Apply';
                $rootScope.signup_usertypes = true;
                $scope.userTypes = [{user_type : 'Professional', user_type_id : '2'},
                    {user_type : 'Student/Graduate', user_type_id : '8'}
                ];
            }
            else{
                $rootScope.signup_usertypes = false;
            }
        }
        else{
            $rootScope.signup_id = id;
            $rootScope.signup_type = type;
            $rootScope.signup_direct = true;
        }
        $('.modal').modal('hide');
        ngDialog.closeAll();
        $('#signup_modal').modal('show');
    };

    $rootScope.QuickApplyJob = function (id, type) {
        $scope.signup = {};
        if(id){
            $rootScope.signup_id = id;
            $rootScope.signup_type = type;
            $rootScope.user_type_id = '';
            $scope.userTypes = [{user_type : 'Professional', user_type_id : '2'},
                {user_type : 'Student/Graduate', user_type_id : '8'}
            ];
            $('.modal').modal('hide');
            ngDialog.closeAll();
            $('#quick_apply_modal').modal('show');
        }
    };



    /*Start open signup by param*/
    // $rootScope.OpenSignUP = function(){
    // 		console.log($routeParams.user_type);
    // 		submitUserType($routeParams.user_type,$routeParams.user);
    // else if($routeParams.user_type == 3){
    // 	$rootScope.submitUserType($routeParams.user_type,'Trainer');
    // }
    // else if($routeParams.user_type == 4){
    // 	$rootScope.submitUserType($routeParams.user_type,'Corporate');
    // }
    // else if($routeParams.user_type == 5){
    // 	$rootScope.submitUserType($routeParams.user_type,'Training Provider');
    // }
    // else if($routeParams.user_type == 6){
    // 	$rootScope.submitUserType($routeParams.user_type,'Executive Education Provider');
    // }
    // else if($routeParams.user_type == 8){
    // 	$rootScope.submitUserType($routeParams.user_type,'Student/Graduate');
    // }
    // else if($routeParams.user_type == 9){
    // 	$rootScope.submitUserType($routeParams.user_type,'Recruiter');
    // }
    // else if($routeParams.user_type == 10){
    // 	$rootScope.submitUserType($routeParams.user_type,'University');
    // }
    // }

    // if($routeParams.user_type){
    // 	$rootScope.OpenSignUP();
    // }
    /*End open signup by param*/

    /*Start Apply Sign Up*/
    // $rootScope.applySignUp = function($scope,id,type){

    // 	alert('in');
    // 	$scope.id = id;
    // 	$scope.type = type;

    // 	$scope.direct_signup = false;
    // 	$scope.type = type;
    // 	$scope.id = id;
    // 	if(type == 'job-apply'){
    // 		$scope.title = 'You are applying for job';
    // 		$scope.btn_text = 'Apply';
    // 		$scope.UserTypeRequired = true;
    // 	}

    // 	$rootScope.ShowSignUp('00', 'indirect');
    // }
    $scope.getUserType = function (userType) {
        $rootScope.user_type_id = userType.user_type_id;
        $rootScope.user_type_name = userType.user_type;
        if(!$rootScope.OTPrequired){
            if(userType == 4){
                $rootScope.landlineRequired = true;
                $rootScope.emailProviderRequired = true;
            }
            else if(userType == 5){
                $rootScope.landlineRequired = true;
            }
            else if(userType == 9){
                $rootScope.emailProviderRequired = true;
            }
            else{
                $rootScope.landlineRequired = false;
                $rootScope.emailProviderRequired = false;
            }
        }

    };
    /*End Apply Sign Up*/

    $rootScope.submitUserType = function (userType, userTypeName) {
        $rootScope.user_type_id = userType;
        // $scope.direct_signup = true;
        $rootScope.signup_direct = true;
        $rootScope.user_type_name = userTypeName;
        $rootScope.ShowSignUp();
        $rootScope.OTPrequired = false;
        if(!$rootScope.OTPrequired){
            if(userType == 4){
                $rootScope.landlineRequired = true;
                $rootScope.emailProviderRequired = true;
            }
            else if(userType == 5){
                $rootScope.landlineRequired = true;
            }
            else if(userType == 9){
                $rootScope.emailProviderRequired = true;
            }
            else{
                $rootScope.landlineRequired = false;
                $rootScope.emailProviderRequired = false;
            }
        }

    };

    function submitUserType(userType, userTypeName) {
        $scope.direct_signup = true;
        $rootScope.user_type_id = userType;
        $rootScope.user_type_name = userTypeName;
        $rootScope.ShowSignUp();
        $rootScope.OTPrequired = false;
        if(!$rootScope.OTPrequired){
            if(userType == 4){
                $rootScope.landlineRequired = true;
                $rootScope.emailProviderRequired = true;
            }
            else if(userType == 5){
                $rootScope.landlineRequired = true;
            }
            else if(userType == 9){
                $rootScope.emailProviderRequired = true;
            }
            else{
                $rootScope.landlineRequired = false;
                $rootScope.emailProviderRequired = false;
            }
        }

    }

    $scope.changeNumber = function(){
        $scope.signup.mobile = '';
        $scope.signup.otp = '';
        $rootScope.otp_sent = false;
        $rootScope.otp_verified = false;
        $rootScope.otp_incorrect = false;
    };

    $scope.resendOTP = function(){
        $rootScope.sendOTP($scope.signup.country.phonecode,$scope.signup.mobile);
    };

    $scope.submitOTP = function(){
        $rootScope.verifyOTP($scope.signup.country.phonecode,$scope.signup.mobile,$scope.signup.otp);
    };

    $scope.submitSignupForm = function() {
        if($rootScope.OTPrequired){
            if($rootScope.otp_sent === false && $rootScope.otp_verified === false){
                $scope.sendOTP($scope.signup.country.phonecode,$scope.signup.mobile);
            }
            else if($rootScope.otp_sent === true && $rootScope.otp_verified === false ){
                if($scope.signup.otp.length >= 4){
                    $rootScope.verifyOTP($scope.signup.country.phonecode,$scope.signup.mobile,$scope.signup.otp);
                }
                else{
                    $rootScope.otp_verified = false;
                    $rootScope.otp_incorrect = true;
                }
            }
            else if($rootScope.otp_sent === true && $rootScope.otp_verified === true ){
                if($rootScope.user_type_id == 4){
                    if(!$rootScope.emailProviderExists){
                        if(!$rootScope.landlineExists){
                            $scope.submitSignup();
                        }
                    }
                }
                else if($rootScope.user_type_id == 5){
                    if(!$rootScope.landlineExists){
                        $scope.submitSignup();
                    }
                }
                else if($rootScope.user_type_id == 9){
                    if(!$rootScope.emailProviderExists){
                        $scope.submitSignup();
                    }
                }
                else{
                    $scope.submitSignup();
                }
            }
        }
        else{
            if($rootScope.user_type_id == 4){
                if(!$rootScope.emailProviderExists){
                    if(!$rootScope.landlineExists){
                        $scope.submitSignup();
                    }
                }
            }
            else if($rootScope.user_type_id == 5){
                if(!$rootScope.landlineExists){
                    $scope.submitSignup();
                }
            }
            else if($rootScope.user_type_id == 9){
                if(!$rootScope.emailProviderExists){
                    $scope.submitSignup();
                }
            }
            else{
                $scope.submitSignup();
            }
        }
    };

    $scope.submitSignup = function(){
        $scope.signup.user_type_id = $rootScope.user_type_id;
        $scope.signup.otp_verified = $rootScope.otp_verified;
        $scope.signup.direct_signup = $rootScope.signup_direct;
        if($rootScope.signup_direct === false){
            $scope.signup.type = $rootScope.signup_type;
            $scope.signup.id = $rootScope.signup_id;
        }
        $rootScope.loading = true;
        $('.loading-overlay').show();
        $scope.campaign_val = {};

        if($rootScope.campaign){
            $scope.campaign_val = $rootScope.campaign;
        }
        $http({
            method: 'post',
            url: 'api/account/signupPost',
            headers: {'Content-Type': 'application/json'},
            data: {signup: $scope.signup, location:$scope.location, campaign: $scope.campaign_val}
        }).then(function(resp,status) {
            $('.loading-overlay').hide();
            if (resp.data.status === 200) {

                $location.path('/home');

                $rootScope.showGuide = false;
                $localStorage.userData = $rootScope.showGuide;

                $scope.signup = {};
                // $scope.closeThisDialog();
                $('#signup_modal').modal('hide');
                swal({
                        html:true,
                        title:'Thanks for registering with Training Calendar',
                        text: resp.data.message,
                        confirmButtonText: 'Close',
                        //confirmButtonColor: '#904d97',
                        type:'success',
                        allowOutsideClick:false

                    },
                    function(isConfirm) {
                        if (isConfirm) {
                        }
                    });
            }
            else{
                swal('',resp.data.message,'error');
            }
            $rootScope.loading = false;
        });
    };

    $scope.resendVerificationMail = function(){
        // console.log('Resend Verification Mail');
    };

    if(!$rootScope.userData || !$rootScope.userData.authenticated) {
        if($routeParams['user_type_id'] && $routeParams['user_type_name']){
            // $rootScope.ShowSignUp();
        }
        // function ShowSignUpUser(userType, userTypeName){
        //     $scope.signup.user_type_id = userType;
        //     $rootScope.user_type_id = userType;
        //     $rootScope.user_type_name = userTypeName;
        //     $rootScope.ShowSignUp();
        // }
    }

});


app.controller('QuickApplyCtrl', function ($localStorage,$sessionStorage,$scope,$http,$location, $rootScope,$window,AuthenticationService,$timeout,ngDialog,$routeParams,tcCommon,tcAjax) {

    $scope.signup = {};
    $scope.inputType = 'password';
    $rootScope.otp_sent = false;
    $rootScope.otp_verified = false;
    $rootScope.otp_incorrect = false;
    $rootScope.emailExists = false;
    $rootScope.mobileExists = false;
    $scope.userTypes = {};
    $rootScope.direct_signup = true;



    $rootScope.QuickApplyJob = function (id, type) {
        $scope.signup = {};
        if(id){
            $rootScope.signup_id = id;
            $rootScope.signup_type = type;
            $rootScope.user_type_id = '';
            $scope.userTypes = [{user_type : 'Professional', user_type_id : '2'},
                {user_type : 'Student/Graduate', user_type_id : '8'}
            ];
            $('.modal').modal('hide');
            ngDialog.closeAll();
            $('#quick_apply_modal').modal('show');
        }
    };

    $scope.submitSignupForm = function() {

        var video_cv = document.getElementById('cv_file').files[0];

        if(video_cv){
            $scope.signup.user_type_id = $rootScope.user_type_id;
            $scope.signup.direct_signup = false;
            $scope.signup.type = $rootScope.signup_type;
            $scope.signup.id = $rootScope.signup_id;
            $scope.signup.otp_verified = false;
            $rootScope.loading = true;

            var fd = new FormData();
            fd.append('cvUpload',video_cv);
            fd.append('signup',JSON.stringify($scope.signup));

            var url = 'api/account/signupPost';
            var params = fd;

            tcAjax.httpUpload(params,url,true,
                function(resp){
                    $rootScope.loading = false;

                    if (resp.status === 200) {
                        $location.path('/jobs');
                        $rootScope.showGuide = false;
                        $localStorage.userData = $rootScope.showGuide;
                        $scope.signup = {};
                        $('#quick_apply_modal').modal('hide');
                        swal('Thanks for registering with Training Calendar',resp.message,'success');
                        $('#cv_file').val();
                    }
                    else{
                        swal('',resp.message,'error');
                    }

                },
                function(error) {

                }
            );
        }
        else{
            swal('CV Required','Please Upload your CV File','warning');
        }
    };

});

app.controller('DemoPopupCtrl', ['$localStorage','$sessionStorage','$scope','$http','$location','$rootScope','$window','$timeout','ngDialog','$routeParams', function ($localStorage,$sessionStorage,$scope,$http,$location, $rootScope,$window,AuthenticationService,$timeout,ngDialog,$routeParams) {

    $scope.industries = {};
    $scope.demo = {};
    if($rootScope.campaign){
        $scope.campaign_val = $rootScope.campaign;
    }

    if($rootScope.userData && $rootScope.userData.authenticated)
    {
        $scope.demo['email'] = $rootScope.userData.email;
        $scope.demo['name'] = $rootScope.userData.firstname;
        $scope.demo['company_name'] = $rootScope.userData.company_name;
        $scope.demo['mobile'] = $rootScope.userData.mobile;
    }

    $http({
        method: 'post',
        url: 'api/helper/get_industries_api',
        headers: {'Content-Type': 'application/json'},
    })
        .then(function(resp,status) {
            $scope.industries = resp.data.industries;
            setTimeout( function(){
                $('select').selectpicker('refresh');
            },100);
        });


    $scope.submitDemoForm = function() {
        $scope.loaded = true;
        $('.loading-overlay').show();
        $http({
            method: 'post',
            url: 'api/campaign/schedule_demo',
            headers: {'Content-Type': 'application/json'},
            data: {demo: $scope.demo,  campaign: $scope.campaign_val}
        }).then(function(resp,status) {
            $('.loading-overlay').hide();
            if (resp.data.status == 200) {
                $scope.signup = {};
                $scope.closeThisDialog();
                swal('Thank you ',resp.data.message,'success');
            }
            else{
                swal('',resp.data.message,'warning');
            }
            $scope.loaded = false;
        });
    };

}]);

app.controller('ForgotPassCtrl', ['$localStorage','$sessionStorage','$scope','$http','$location','$rootScope','$window','$timeout','ngDialog', function ($localStorage,$sessionStorage,$scope,$http,$location, $rootScope,$window,AuthenticationService,$timeout,ngDialog) {


    $rootScope.ShowForgotPass = function (id,type) {
        $('.modal').modal('hide');
        $('#forgot_password_modal').modal('show');
        if(id){
            $rootScope.signup_id = id;
            $rootScope.signup_type = type;
            $rootScope.signup_direct = false;
        }
        else{
            $rootScope.signup_id = id;
            $rootScope.signup_type = type;
            $rootScope.signup_direct = true;
        }
        // $('.modal').modal('hide');
        // ngDialog.closeAll();
        //    var dialog = ngDialog.open({
        //        template: 'ngviews/account/forgotpass.html',
        //        showClose: false,
        //        keyboard: false,
        //        closeByEscape: false,
        //        closeByDocument: false,
        //        closeByNavigation: true
        //    });
    };


    $scope.submitForgotPassForm = function() {
        $('.loading-overlay').show();
        $scope.loaded = true;
        $http({
            method: 'post',
            url: 'api/account/forgot_password',
            headers: {'Content-Type': 'application/json'},
            data: {email:$scope.email}
        })
            .then(function(resp,status) {
                $('.loading-overlay').hide();
                /*showNotification('alert-'+resp.data.type, resp.data.message, 'top', 'center', 'animated bounceInUp', 'animated bounceOutUp');*/
                if (resp.data.type === 'success') {
                    swal('',resp.data.message,'success');
                    $scope.closeThisDialog();
                    $location.path('/home');
                }
                else if(resp.data.type === 'error') {
                    swal('',resp.data.message,'warning');
                    $scope.emailNotExists = true;
                }

                $scope.loaded = false;
            });

    };
}]);



app.controller('CoursePreRegisterCtrl', ['$localStorage','$sessionStorage','$scope','$http','$location','$rootScope','$window','$timeout','ngDialog', function ($localStorage,$sessionStorage,$scope,$http,$location, $rootScope,$window,AuthenticationService,$timeout,ngDialog) {
    $scope.submitPreRegister = function(lead, course)
    {
        $('.loading-overlay').show();
        $scope.process = true;
        $http({
            method: 'post',
            url: 'api/courses/lead',
            headers: {'Content-Type': 'application/json'},
            data: JSON.stringify({course: course, lead: lead}),
        })
            .then(function(response,status) {
                $('.loading-overlay').hide();
                if (response.data.status === 200) {
                    $scope.closeThisDialog();
                    $scope.process = false;
                    $('#lead-modal').modal('toggle');
                    swal({
                            title: "",
                            text: '<a class="btn purple-btn" target="_blank" href="'+response.data.course_external_link+'">Click here to view Course</a>',//response.data.message,
                            html:true,
                            type: "success",
                            showCancelButton: false,
                            showConfirmButton: false,
                            cancelButtonText: "Close",
                            allowOutsideClick: true
                        },
                        function(isConfirm) {
                            if (isConfirm) {
                                // ngDialog.closeAll();
                                swal.close();
                                // $location.path('/home');
                            }
                        });
                }
                else
                {
                    swal('Try Again!', response.data.message,response.data.type);
                }
            });
    };
}]);

app.controller('EnquiryCtrl', ['$localStorage','$sessionStorage','$scope','$http','$location','$rootScope','$window','$timeout','ngDialog', function ($localStorage,$sessionStorage,$scope,$http,$location, $rootScope,$window,AuthenticationService,$timeout,ngDialog) {
    $scope.submitEnquiry = function(enquiry, id, type)
    {
        $('.loading-overlay').show();
        $scope.enquiryForm = {};
        $scope.process = true;
        //console.log($scope);
        $http({
            method: 'post',
            url: 'api/account/enquiry',
            headers : {'Content-Type': 'application/json'},
            data: {enquiry: enquiry, enquiry_about: id, type:type},
        }).then(function(response,status) {
            $('.loading-overlay').hide();
            if (response.data.status === 200) {
                $rootScope.closeDialog();
            }
            swal('', response.data.message,response.data.type);
        });
    };
}]);

// app.controller('SigningInCtrl', ['$localStorage','$sessionStorage','$scope','$http','$location','$rootScope','$window','AuthenticationService','$timeout','ngDialog', function ($localStorage,$sessionStorage,$scope,$http,$location, $rootScope,$window,AuthenticationService,$timeout,ngDialog) {

// 	$scope.loaded = false;
// 	$scope.inputType = 'password';

// 	$scope.hideShowPassword = function(){
// 	    if ($scope.inputType == 'password')
// 	      $scope.inputType = 'text';
// 	    else
// 	      $scope.inputType = 'password';
// 	};
// 	//Data not posted issue
// 	if(!$rootScope.userData.authenticated)
// 	{
// 		$scope.signin = {};
// 	}

// 	$scope.SignIn = function(){

// 	// $('#signin_modal').modal('show');
// 		// $('.modal').not('#signin_modal').modal('hide');
// 	}
// 	$scope.ForgotPassword = function(){
// 		$('#forgotpass_modal').modal('show');
// 		$('.modal').not('#forgotpass_modal').modal('hide');
// 	}
// 	$scope.SignUp = function(){
// 		$('#signup_modal').modal('show');
// 		$('.modal').not('#signup_modal').modal('hide');
// 	}

// 	$scope.submitForm = function () {
// 		$('.loading-overlay').show();
// 		$scope.loaded = true;
// 		$http.post('api/account/signinPost', JSON.stringify({username:$scope.signin.username, password:$scope.signin.password}) )
// 			.then(function (response) {
// 			$('.loading-overlay').hide();
// 			$scope.loaded = false;
// 			if(response.data.status == 200){
// 				if(response.data.user)
// 				{
// 					$rootScope.userData = response.data.user;
// 					$scope.userData = response.data.user;
// 					$localStorage.userData = $rootScope.userData;
// 					$scope.signin = [];
// 					$rootScope.tempData = response.data.temp;
// 					$localStorage.tempData = $rootScope.tempData;
// 					if(!$rootScope.tempData.agreed_to_terms)
// 					{
// 						// agree_modal
// 						$('#agree_modal').modal({
// 			                show: true,
// 			                keyboard: false,
// 			                backdrop: 'static'
// 			            });
// 					}
// 					else if(response.data.user.profile_completed == 1)
// 					{
// 						$('#signin_modal').modal('hide');
// 						$location.path('user/dashboard');
// 					}
// 					else
// 					{
// 						$('#signin_modal').modal('hide');
// 						$location.path('user/profile/edit');
// 						setTimeout(function(){
// 							swal('Profile Incomplete!','Please fill up the profile details','info')
// 						},500);
// 					}
// 				}
// 			}
// 			else if(response.data.status == 302)
// 			{
// 				/*swal({title:'',text:response.data.message,confirmButtonText: 'click here to send verification email again!', closeOnConfirm: false,type:'info',allowOutsideClick:false },
// 					 function(isConfirm) {
// 					if (isConfirm) {

// 						$http({
// 							method: 'post',
// 							url: 'api/account/resend_verify_mail',
// 							headers: {'Content-Type': 'application/json'},
// 							data: {signup: $scope.signup}
// 						}).then(function(resp,status) {
// 							swal('','Verification mail sent. Still not received the email, please contact our support team.','info')
// 							// $location.path('/home');
// 						});
// 					}
// 				});	*/
// 				swal({
// 					title:'',
// 					text:response.data.message,
// 					confirmButtonText: 'Resend verification email', closeOnConfirm: false,
// 					type:'info',
// 					allowOutsideClick:true
// 				},function(isConfirm) {
// 					if (isConfirm) {
// 						$('.loading-overlay').show();
// 						$http({
// 							method: 'post',
// 							url: 'api/account/resend_verify_mail',
// 							headers: {'Content-Type': 'application/json'},
// 							data: JSON.stringify({email: $scope.signin.username})
// 						}).then(function(resp,status) {
// 							swal('','Verification mail sent. Still not received the email, please contact our support team.','info')
// 							// $location.path('/home');
// 							$('.loading-overlay').hide();
// 						});
// 					}
// 				});

// 			}
// 			else{
// 				swal({title:'',text:response.data.message,type:'info',allowOutsideClick:false });
// 			}

// 		});


// 	};

// 	$('.show-pass-icon').on('click', function(){
// 		var findpwd = $(this).siblings('.show-pass');
// 		findpwd.toggleClass('active');
// 		$(this).toggleClass('active');

// 		if(findpwd.hasClass('active')){
// 			findpwd.attr('type', 'text').focus();
// 			$(this).addClass('fa-eye').removeClass('fa-eye-slash');
// 		}
// 		else{
// 			findpwd.attr('type', 'password').focus();
// 			$(this).addClass('fa-eye-slash').removeClass('fa-eye');
// 		}
// 	});

// }]);

// app.controller('SigningUpCtrl', ['$scope','$http','$cookies','$location','$timeout','$routeParams', function ($scope,$http,$cookies,$location,$timeout,$routeParams) {

// 	// $scope.SignIn = function(){
// 	// 	$('#signin_modal').modal('show');
// 	// 	$('#forgotpass_modal').modal('hide');
// 	// 	$('#signup_modal').modal('hide');
// 	// }
// 	// $scope.ForgotPassword = function(){
// 	// 	$('#forgotpass_modal').modal('show');
// 	// 	$('#signin_modal').modal('hide');
// 	// 	$('#signup_modal').modal('hide');
// 	// }
// 	// $scope.SignUp = function(){
// 	// 	$('#signup_modal').modal('show');
// 	// 	$('#forgotpass_modal').modal('hide');
// 	// 	$('#signin_modal').modal('hide');
// 	// }

// 	// $scope.closeModal = function(){
// 	// 	$('.modal').modal('hide');
// 	// }




// 	$scope.inputType = 'password';
// 	$scope.hideShowPassword = function(){
// 	    if ($scope.inputType == 'password')
// 	      $scope.inputType = 'text';
// 	    else
// 	      $scope.inputType = 'password';
// 	};

// 	$scope.SignIn = function(){
// 		$('#signin_modal').modal('show');
// 		// $('#forgotpass_modal').modal('hide');
// 		// $('#signup_modal').modal('hide');
// 		$('.modal').not('#signin_modal').modal('hide');
// 	}
// 	$scope.ForgotPassword = function(){
// 		$('#forgotpass_modal').modal('show');
// 		// $('#signin_modal').modal('hide');
// 		// $('#signup_modal').modal('hide');
// 		$('.modal').not('#forgotpass_modal').modal('hide');
// 	}
// 	$scope.SignUp = function(){
// 		$('#signup_modal').modal('show');
// 		// $('#forgotpass_modal').modal('hide');
// 		// $('#signin_modal').modal('hide');
// 		$('.modal').not('#signup_modal').modal('hide');
// 	}
// 	$scope.loaded = false;

// 	$scope.registration = {
// 		firstname: '',
// 		email: '',
// 		mobile: '',
// 		password: '',
// 		userType:'',
// 	};
// 	$scope.userTypes = {};
// 	if(angular.equals({}, $scope.userTypes))
// 		{
// 		$http({
// 			method: 'post',
// 			url: 'api/account/getUserTypes',
// 			headers: {'Content-Type': 'application/json'},
// 		})
// 			.then(function(resp,status) {
// 			$scope.userTypes = resp.data.userTypes;

// 			$timeout(function(){
// 				$('select').selectpicker('refresh');
// 			}, 50);
// 			$timeout(function(){
// 				if($routeParams.type){
// 					$('select[name=userType]').selectpicker('val',$routeParams.type);
// 					$('select[name=userType]').trigger('change');
// 				}
// 			},500)
// 		});
// 	}


// 	$scope.verifyEmail = function(email) {
// 		$http({
// 			method: 'post',
// 			url: 'api/account/verifyEmail',
// 			headers: {'Content-Type': 'application/json'},
// 			data: {email: email}
// 		})
// 			.then(function(resp,status) {
// 			if (resp.data.status === 302) {
// 				// $scope.myForm.ccNumber.$setValidity("ccNumber", false);
// 				// showNotification('alert-'+resp.data.type, resp.data.message, 'top', 'center', 'animated bounceInUp', 'animated bounceOutUp');
// 				// swal('',resp.data.message,'warning');
// 				// $scope.signupForm.$setValidity('email', false, $scope.signupForm);
// 				$scope.emailExists = true;
// 			}
// 			else if (resp.data.status === 200) {
// 				$scope.emailExists = false;
// 			}
// 		});
// 	}

// 	$scope.verifyMobile = function(mobile) {
// 		if(mobile && mobile.length){
// 			$http({
// 				method: 'post',
// 				url: 'api/account/verifyMobile',
// 				headers: {'Content-Type': 'application/json'},
// 				data: {mobile: mobile}
// 			})
// 			.then(function(resp,status) {
// 				if (resp.data.status === 302) {
// 					// $scope.myForm.ccNumber.$setValidity("ccNumber", false);
// 					// showNotification('alert-'+resp.data.type, resp.data.message, 'top', 'center', 'animated bounceInUp', 'animated bounceOutUp');
// 					// swal('',resp.data.message,'warning');
// 					// $scope.signupForm.$setValidity('mobile', false, $scope.signupForm);
// 					$scope.mobileExists = true;

// 				}
// 				else if (resp.data.status === 200) {
// 					$scope.mobileExists = false;
// 				}
// 			});
// 		}
// 	}


// 	$scope.submitForm = function() {
// 		$scope.loaded = true;
// 		$('.loading-overlay').show();
// 		/*swal({
// 			title:"",
// 			text:"Registering..",
// 			imageUrl: "assets/images/loader_seq.gif",
// 			showConfirmButton:false,
// 			allowOutsideClick:false
// 		});	*/

// 		$http({
// 			method: 'post',
// 			url: 'api/account/signupPost',
// 			headers: {'Content-Type': 'application/json'},
// 			data: {signup: $scope.signup, location:$scope.location}
// 		}).then(function(resp,status) {
// 			$('.loading-overlay').hide();
// 			if (resp.data.type === 'success') {

// 				$scope.signup = {};
// 				$('#signup_modal').modal('hide');
// 				swal({
// 					title:'',
// 					text:'Registration Successful. Please verify your email',
// 					confirmButtonText: 'Start your Training..',
// 					//confirmButtonColor: '#904d97',
// 					type:'success',
// 					allowOutsideClick:false

// 				},
// 					 function(isConfirm) {
// 					if (isConfirm) {
// 						$timeout(function(){
// 							//swal.close();
// 							$('#signup_modal').modal('hide');
// 							$location.path('/home');
// 						});
// 					}
// 				});
// 				//$location.path('/signup/success');
// 			}
// 			else{
// 				swal('',resp.data.message,'error');
// 			}
// 			$scope.loaded = false;
// 		});


// 	};

// 	$scope.resendVerificationMail = function(){
// 		console.log('Resend Verification Mail');
// 	}


// 	$('.show-pass-icon').on('click', function(){
// 		var findpwd = $(this).siblings('.show-pass');
// 		findpwd.toggleClass('active')
// 		$(this).toggleClass('active');

// 		if(findpwd.hasClass('active')){
// 			findpwd.attr('type', 'text').focus();
// 			$(this).addClass('fa-eye').removeClass('fa-eye-slash');
// 		}
// 		else{
// 			findpwd.attr('type', 'password').focus();
// 			$(this).addClass('fa-eye-slash').removeClass('fa-eye');
// 		}
// 	});


// }]);

app.controller('ModalCtrl', ['$scope','$http','$cookies','$location','$timeout','$routeParams', function ($scope,$http,$cookies,$location,$timeout,$routeParams) {

    $scope.OpenModal = function(){
        $('#common_modal').modal('show');
    };

}]);




if(typeof(Storage)!=="undefined") {
    // $http({
    // 	method: 'POST',
    // 	url: 'api/account/unset_session',
    // 	headers: {'Content-Type': 'application/json'},
    // })
    // .then(function (resp, status) {

    // });

    // $localStorage.authenticated = false;
    // $localStorage.name = false;
    // $localStorage.loggedAs = false;

    // $rootScope.authenticated = false;
    // $rootScope.name = false;
    // $rootScope.loggedAs = false;

    localStorage.removeItem("authenticated");
    localStorage.removeItem("name");
    localStorage.removeItem("loggedAs");
} else {
    console.log("Sorry! No web storage support..");
}



//changed/10/10
app.run(['$rootScope', '$location', '$cookieStore','$http','$routeParams', '$localStorage','AuthenticationService','$templateCache','$timeout', function($rootScope, $location, $cookieStore, $http, $routeParams, $localStorage, AuthenticationService, $templateCache,$timeout){
    $rootScope.ShowFooter = true;
    // $rootScope.$on('$viewContentLoaded', function() {
    //      $templateCache.removeAll();
    //   });
    // $rootScope.globals = $cookieStore.get('globals') || {};

    // if(!$rootScope.userData)
    // {
    // 	$localStorage.$reset();
    // }
    $rootScope.profile_complete = $localStorage.profile_complete || {};
    $rootScope.userData 		= $localStorage.userData || {};
    $rootScope.tempData 		= $localStorage.tempData || {};
    // console.log($rootScope.userData);
    if ($rootScope.userData) {
        $http.defaults.headers.common['Authorization'] = 'Basic ' + $rootScope.userData; // jshint ignore:line
        // $rootScope.userData.profile_pic = $rootScope.userData.profile_pic;
        // $location.path('/home');
    }

    $rootScope.$on("$routeChangeSuccess", function (event, next, current) {

        /*Start mobile menu overlay*/
        if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
            $('#mobile-body-overly').fadeOut();
        }
        /*End mobile menu overlay*/
        /* Show Hide Footer Start*/
        // if(next){
        // 	if(next.$$route.requireLogin){
        // 		$rootScope.ShowFooter = false;
        // 	}
        // 	else{
        // 		$rootScope.ShowFooter = true;
        // 	}

        // }
        /* Show Hide Footer Ends*/

        $('#search_modal').modal('hide');
        // if(window.location.hash) {
        var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character

        // $('.modal').on('hidden.bs.modal', function () {
        //      	var uri = window.location.toString();
        // 	if (uri.indexOf("#") > 0) {
        // 	    var clean_uri = uri.substring(0, uri.indexOf("#"));
        // 	    window.history.replaceState({}, document.title, clean_uri);
        // 	    $('.modal').modal('hide');
        // 	}
        // });
        if (hash==='signup') {
            // alert('1');
            $('.modal').modal('hide');
            $('#signup_modal').modal('show');
        }else if(hash==='signin'){
            // alert('2');
            $('.modal').modal('hide');
            $('#signin_modal').modal('show');
        }
        else if(hash === 'forgotpass')
        {
            // alert('3');
            $('.modal').modal('hide');
            $('#forgotpass_modal').modal('show');
        }
        // hash found
        // } else {
        //     // No hash found
        // }


        //LEFT MENU TOGGLE

        // $( ".menu-toggle" ).each(function(index) {
        // 	console.log('All'+index);
        // 	if(!$(this).hasClass('toggled')){
        // 		$(this).siblings('.ml-menu').removeClass('show').hide();
        // 		console.log('not matched'+index);
        // 	}
        // });

        // $('.ml-menu').removeClass('show').hide();

        // $( ".ml-menu li" ).each(function(index) {
        // 	console.log('All'+index);
        // 	if($(this).hasClass('active')){
        // 		console.log('hasClass'+index);
        // 		$(this).parent('.ml-menu').addClass('show').show();
        // 	}
        // 	else{
        // 		console.log('noClass'+index);
        // 		$(this).parent('.ml-menu').removeClass('show').hide().addClass('test');
        // 		$(this).parent('.menu-toggle').removeClass('toggled');
        // 	}
        // });
    });

    $rootScope.$on("$routeChangeStart", function (event, next, current) {


        $(".modal").modal('hide');
        if(next.$$route){
            $rootScope.requireLogin = next.$$route.requireLogin;
        }

        if(!$rootScope.tempData.agreed_to_terms &&  $rootScope.userData && $rootScope.userData.authenticated)
        {
            $rootScope.ShowTerms();
        }
        else{
            $rootScope.closeDialog();
        }

        if(next.$$route && next.$$route.requireLogin === true)
        {
            if(angular.equals({}, $rootScope.userData))
            {
                $location.path('/');
                $rootScope.ShowSignIn();
            }
        }



        $("html, body").stop().animate({scrollTop:0}, 500, 'swing', function() { });

        /*Hide dashboard menu overlay*/
        $('body').removeClass('overlay-open');
        if ($(window).width() <= 991){
            $('#side_bar').slideUp();
        }
        if ($body.hasClass('overlay-open')) { $overlay.fadeIn(); } else { $overlay.fadeOut(); }
        $('#navbar-collapse').slideUp();

        $timeout(function(){
            var ctype = $location.path();
            ctype = ctype.substr(1);

            if(ctype == 'courses' || ctype == 'executive-education' || ctype == 'conferences' || ctype == 'rfps' || ctype == 'trainer-vacancies' || ctype == 'jobs' || ctype == 'trainers'|| ctype == 'training-providers' || ctype == 'free-courses'|| ctype == 'jobs_map' || ctype == 'campus-jobs' || ctype == 'jobs_map/campus')
            {
                if(ctype == 'jobs_map'){
                    ctype = 'jobs';
                }
                else if(ctype == 'jobs_map/campus'){
                    ctype = 'campus-jobs';
                }


                var ctypes = [{name:'Courses',value:'courses'},{name:'Executive Education',value:'executive-education'},
                    {name:'Conferences', value:'conferences'},{name:'RFPs', value:'rfps'},{name:'Trainer Jobs',value:'trainer-vacancies'},
                    {name:'Professional Jobs',value:'jobs'},
                    {name:'Campus Jobs',value:'campus-jobs'},
                    {name:'Free Online Courses',value:'free-courses'}];

                /*$.each(ctypes,function(key,val){
					if(ctype == val.value){
						$rootscope.ctype =val;
						 console.log($rootscope.ctype);
					}
				});	*/
            }


        },10);

        var path = function() { return $location.path();};
        $rootScope.$watch(path, function(newVal, oldVal){

            // $rootScope.activetab = newVal.replace(/\\/g, '');
            $rootScope.activetab = newVal;

            // str =  newVal;
            // $rootScope.activetab = str.replace(/\//g, "");

            // console.log('--'+$rootScope.activetab+'--');

            // setTimeout(function(){
            //  $thisMenu = $('.ml-menu li.active').parent('.ml-menu').siblings('a');
            //  var $content =  $thisMenu.next();
            //  $thisMenu.toggleClass('toggled');
            //  // $content.slideToggle(320);
            // },200)

            $rootScope.showGuideLi = false;
            if(newVal == '/user/dashboard'){
                $rootScope.showGuideLi = true;
            }

        });

        //default banner
        $rootScope.banner_default = 'banner01.jpg';

        //LEFT MENU
        // $timeout(function(){
        // 	$( ".menu-toggle" ).each(function(index) {
        // 		if(!$(this).hasClass('toggled')){
        // 			$(this).siblings('.ml-menu').hide();
        // 			console.log('not '+index);
        // 		}
        // 		console.log('All '+index);
        // 	});
        // },500);

        // $( ".ml-menu li" ).each(function(index) {
        // 	console.log('All'+index);
        // 	if($(this).hasClass('active')){
        // 		console.log('hasClass'+index);
        // 		$(this).parent('.ml-menu').addClass('active');
        // 	}
        // 	else{
        // 		console.log('noClass'+index);
        // 		$(this).parent('.ml-menu').removeClass('show').hide();
        // 		$(this).parent('.menu-toggle').removeClass('toggled');
        // 	}
        // });
    });

    // $rootScope.$on('$locationChangeStart', function (event, next, current) {
    //     // redirect to login page if not logged in
    //     if ($location.path() !== '/signin' && !$rootScope.globals.UserData) {
    //         $location.path('/home');
    //     }
    // });

}]);
//changed/10/10

// app.run(['$rootScope', '$location', function($rootScope, $location,$timeout){

// 	var path = function() { return $location.path();};
// 	$rootScope.$watch(path, function(newVal, oldVal){
// 		$rootScope.activetab = newVal;

// 		setTimeout(function(){
// 			$thisMenu = $('.ml-menu li.active').parent('.ml-menu').siblings('a');
// 			var $content =  $thisMenu.next();
// 			$thisMenu.toggleClass('toggled');
// 			$content.slideToggle(320);
// 		},400)

// 	});
// }]);
/*
app.run(function($rootScope, $route, $location){
	//Bind the `$locationChangeSuccess` event on the rootScope, so that we dont need to
	//bind in induvidual controllers.

	$rootScope.$on('$locationChangeSuccess', function() {
		//alert($location.path());
		$rootScope.actualLocation = $location.path();
	});

	$rootScope.$watch(function () {return $location.path()}, function (newLocation, oldLocation) {
		if($rootScope.actualLocation === newLocation) {
			alert('Why did you use history back?');
		}
	});
});*/

/*app.run(['$rootScope','$location', '$http',function($rootScope,$location,$http){
      $rootScope.$on('$routeChangeStart', function(event,next, current) {

      	for(var i in window.routes) {
            if(next.indexOf(i) != -1) {
                if(window.routes[i].requireLogin && !SessionService.getUserAuthenticated()) {
                    alert("You need to be authenticated to see this page!");
                    event.preventDefault();
                }
            }
        }

  //      $http({
		// 		method: 'POST',
		// 		url: 'api/account/isLoggedIn',
		// 		headers: {'Content-Type': 'application/json'},
		// 		data: JSON.stringify({})
		// 	})
		// 	.then(function (resp, status) {
		// 		console.log(resp.data);
		// 		if(resp.data.status == 200)
		// 		{
		// 			$rootScope.authenticated = $localStorage.authenticated;
		// 			$rootScope.name = $localStorage.name;
		// 			$rootScope.loggedAs = $localStorage.loggedAs;
		// 			$scope.user_type_id = $localStorage.user_type_id;
		// 		}
		// 		else{
		// 			$rootScope.authenticated = false;
		// 			$rootScope.name = false;
		// 			$rootScope.loggedAs = false;
		// 			$scope.user_type_id = false;
		// 		}
		// });

    });
}]);*/




app.filter('strLimit', ['$filter', function($filter) {
    return function(input, limit) {
        if (! input) return;
        if (input.length <= limit) {
            return input;
        }

        return $filter('limitTo')(input, limit) + '...';
    };
}]);



app.controller('searchCtrl', ['$localStorage','$sessionStorage','$scope','$http','$location','$rootScope','$window','$routeParams','$timeout', function ($localStorage,$sessionStorage,$scope,$http,$location, $rootScope,$window,$routeParams,$timeout) {

    $scope.ctypes = [{name:'Courses',value:'courses'},{name:'Executive Education',value:'executive-education'},
        {name:'Conferences', value:'conferences'},{name:'RFPs', value:'rfps'},{name:'Trainer Jobs',value:'trainer-vacancies'},
        {name:'Professional Jobs',value:'jobs'},
        // {name:'Trainers',value:'trainers'},
        {name:'Campus Jobs',value:'campus-jobs'},
        // {name:'Training Providers',value:'training-providers'},
        {name:'E-Learning Courses',value:'free-courses'}
        //,"RFP's",'Trainer Vacancies','Trainers','Training Providers','Free Online Courses'
    ];
    $scope.ctype= $scope.ctypes[0];
    $scope.showme = false;


    $rootScope.searchedKeywords = $localStorage.searchedKeywords || {};
    // console.log($rootScope.searchedKeywords);
    /*$timeout(function(){
		$scope.ctype = $location.path();
		console.log($scope.ctype.substr(1));

		$('select[name=ctype]').val($scope.ctype.substr(1));
		$('.selectpicker').selectpicker('refresh');
		$('select').trigger('change');

	},500);*/


    $scope.onSelect = function($item, $model, $label,ctype){
        $scope.$item = $item;
        $scope.$model = $model;
        $scope.$label = $label;
        if(ctype.value == 'executive-education'){
            ctype.value = 'courses';
        }
        $location.path('/'+ ctype.value+'/'+ $scope.$item.url);
        // console.log('/'+ ctype.value+'/'+ $scope.$item.url);
    };

    $scope.clickSearchbtn = function(){
        setTimeout(function () {
            angular.element('#searchbtn').trigger('click');
        }, 0);
    };


    $scope.getKeywords= function(keyword)
    {

        return $http.post('api/search/get_keywords', {
            keyword: keyword,
            type: $scope.ctype.name,
        }).then(function(response){
            return response.data.results.map(function(item){
                return item;
            });
        });
    };

    $scope.toggle = function(keyword) {
        $scope.showme = false;
        $scope.keyword = keyword;
    };

    $scope.saveKeyword = function()
    {
        $rootScope.searchedKeywords = $localStorage.searchedKeywords || {};
        if($rootScope.searchedKeywords)
        {
            if($scope.keyword && $rootScope.searchedKeywords.indexOf($scope.keyword) == -1)
            {
                $rootScope.searchedKeywords.push($scope.keyword);
                $localStorage.searchedKeywords = $rootScope.searchedKeywords;
                // $localStorage.searchedKey = 1;
                // console.log($localStorage.searchedKey);
                if ($rootScope.searchedKeywords.length > 10) {
                    $rootScope.searchedKeywords.shift(); // removes the first element from an array
                }
            }
        } else {
            $rootScope.searchedKeywords = [];
        }
        // console.log($rootScope.searchedKeywords);
    };

    $scope.setKeywords = function(keyword){
        $scope.keyword = keyword;
        // $rootscope.scrollTop();

    };
}]);



app.controller('GlobalSearchCtrl', ['$localStorage','$sessionStorage','$scope','$http','$location','$rootScope','$window','$routeParams','$timeout', function ($localStorage,$sessionStorage,$scope,$http,$location, $rootScope,$window,$routeParams,$timeout) {

    $scope.ctypes = [{name:'Courses',value:'courses'},{name:'Executive Education',value:'executive-education'},
        {name:'Conferences', value:'conferences'},{name:'RFPs', value:'rfps'},{name:'Trainer Jobs',value:'trainer-vacancies'},
        {name:'Professional Jobs',value:'jobs'},
        // {name:'Trainers',value:'trainers'},
        {name:'Campus Jobs',value:'campus-jobs'},
        // {name:'Training Providers',value:'training-providers'},
        {name:'Free Online Courses',value:'free-courses'}
        //,"RFP's",'Trainer Vacancies','Trainers','Training Providers','Free Online Courses'
    ];
    $scope.ctype= $scope.ctypes[0];
}]);

function mainController($scope) {
    // We will create an seo variable on the scope and decide which fields we want to populate
    $scope.seo = {
        pageTitle : 'Training Calendar',
        pageDescription : '',
        ogtitle : '',
        ogdescription : '',
        ogimage:''
    };
}



angular.module('TCAPP').provider('ngIntlTelInput', function () {
    var me = this;
    var props = {};
    var setFn = function (obj) {
        if (typeof obj === 'object') {
            for (var key in obj) {
                props[key] = obj[key];
            }
        }
    };
    me.set = setFn;

    me.$get = ['$log', function ($log) {
        return Object.create(me, {
            init: {
                value: function (elm) {
                    if (!window.intlTelInputUtils) {
                        $log.warn('intlTelInputUtils is not defined. Formatting and validation will not work.');
                    }
                    elm.intlTelInput(props);
                }
            },
        });
    }];
});



app.filter('propsFilter', function() {
    return function(items, props) {
        var out = [];

        if (angular.isArray(items)) {
            items.forEach(function(item) {
                var itemMatches = false;

                var keys = Object.keys(props);
                for (var i = 0; i < keys.length; i++) {
                    var prop = keys[i];
                    var text = props[prop].toLowerCase();
                    if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                        itemMatches = true;
                        break;
                    }
                }

                if (itemMatches) {
                    out.push(item);
                }
            });
        } else {
            // Let the output be the input untouched
            out = items;
        }

        return out;
    };
});


app.filter("randomSrc", function () {
    return function (input) {
        if (input) {
            var sep = input.indexOf("?") != -1 ? "&" : "?";
            return input + sep + "r=" + Math.round(Math.random() * 999999);
        }
    };
});

app.filter('removeHTMLTags', function() {
    return function(text) {
        return  text ? String(text).replace(/<[^>]+>/gm, '') : '';
    };
});



app.filter('reverse', function() {
    return function(items) {
        if(items){
            return items.slice().reverse();
        }

    };
});

app.filter('trusted', ['$sce', function($sce) {
    var div = document.createElement('div');
    return function(text) {
        div.innerHTML = text;
        return $sce.trustAsHtml(div.textContent);
    };
}]);

app.controller('CropCtrl', ['$localStorage','$scope','$http','$location','$rootScope','$cookies','$timeout','ngDialog', function ($localStorage,$scope,$http,$location, $rootScope,$cookies,$timeout,ngDialog) {

    $scope.saveProfilePic = function(myCroppedImage)
    {
        $('.loading-overlay').show();
        $timeout(function()
        {
            var fd = new FormData();
            //var image = document.getElementById('input_profile_pic').files[0];
            fd.append('profile_pic', myCroppedImage);
            fd.append('userData', JSON.stringify($rootScope.userData));
            $scope.process = true;
            $http({
                method: 'POST',
                url: 'api/account/update_profile_pic',
                headers: {'Content-Type': undefined},
                transformRequest: angular.identity,
                data: fd
            }).then(function (resp, status) {
                // var random = (new Date()).toString();
                $localStorage.userData.profile_pic = resp.data.pic;
                $scope.userData.profile_pic = $localStorage.userData.profile_pic;
                $rootScope.userData.profile_pic = $localStorage.userData.profile_pic;
                $rootScope.apply();

            }).finally(function()
            {
                $scope.closeThisDialog('Cancel');
                $('.loading-overlay').hide();
            });
        }, 1000);
    };
}]);
app.controller('ProfileCompleteCtrl', ['$localStorage','$scope','$http','$location','$rootScope','$cookies','$timeout','ngDialog', function ($localStorage,$scope,$http,$location, $rootScope,$cookies,$timeout,ngDialog) {

    var circle;
    var profileCompleteVal = $rootScope.profile_complete.profile_percentage;
    var val = profileCompleteVal / 100;
    var toColor = '#f44336';

    if(val < 0.5){
        toColor = '#f44336';
    }
    else if(val < 0.75){
        toColor = '#ffc107';
    }
    else if(val < 1){
        toColor = '#8bc34a';
    }
    else if(val == 1){
        toColor = '#4caf50';
    }

    setTimeout(function(){
        /*var progressBar = new ProgressBar.Circle('#container', {
		 	color: '#3a3a3a',
		    strokeWidth: 2,
	      	trailColor: '#f4f4f4',
	      	trailWidth: 0.8,
		    // Default: 800
		    duration: 1200,
		    easing: 'easeInOut',
		    from: { color: '#eee' },
		    to: { color: '#000' },
		    step: function(state, circle, attachment) {
		        circle.path.setAttribute('stroke', state.color);
		    }
		});*/


        circle = new ProgressBar.Circle('#progress-container',
            {
                color: '#904d97',
                strokeWidth: 3,
                trailWidth: 1,
                text: {
                    value: ''+ profileCompleteVal +'%'
                },
                from: { color: '#f44336' }, //f57c00, 8bc34a, 4caf50
                to: { color: toColor},
                step: function(state, circle, attachment) {
                    circle.path.setAttribute('stroke', state.color);
                }
            });



    },500);

    setTimeout(function(){

        circle.animate(val, {
            duration: 800
        }, function() { });

    },2000);


}]);

app.controller('CourseFeedbackCtrl', ['$localStorage','$scope','$http','$location','$rootScope','$cookies','$timeout','ngDialog','$routeParams', function ($localStorage,$scope,$http,$location, $rootScope,$cookies,$timeout,ngDialog,$routeParams) {

    setTimeout(function(){
        // console.log($routeParams.seourl);
        var params = {seo_url:$routeParams.seourl,userData:  $rootScope.userData};

        $http({
            method: 'POST',
            url: 'api/feedbackratings/get_feedback_questions',
            headers: {'Content-Type': 'application/json'},
            data: params
        })
            .then(function(resp,status) {
                var res  = resp.data;
                if(res.status == 200){
                    // console.log(res);
                    //  $scope.comments =  {course_comment:'',trainer_comment:''};
                    $scope.course_questions = res.course_questions;
                    $scope.user_questions = res.user_questions;
                    $scope.comments =  res.comments;
                }
                else{
                    swal('',res.message,'warning');
                }

            });

    },3000);


    $scope.getSelectedRating = function (rateFor,index,rating) {
        // console.log(rateFor,index,rating);
        if(rateFor == 'course'){
            $scope.course_questions[index].rating = rating;
        }
        else{
            $scope.user_questions[index].rating = rating;
        }
    };

}]);

app.controller('ScrollCtrl', function(anchorSmoothScroll) {
     anchorSmoothScroll.scrollTo();
});